import React from "react";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

import { Button, Grid, Snackbar } from "@material-ui/core";
import "./css/users.css";

import CustomDataTable from "../../CustomDataTable";
import { Alert } from "@material-ui/lab";
import { ExportClientData } from "../../Operations/Download";
import moment from "moment";

const UserListForm = (props) => {
  const { columns, options, clientList, alertOpen, exportData } = props.state;

  return (
    <div className="userListRoot">
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={props.closeAlert()}
      >
        <Alert severity="error" onClose={props.closeAlert()}>
          Client has not filled up their KYC Application
        </Alert>
      </Snackbar>

      <div className="userListpaper">
        <div className="userListBody">
          <h1>Client List</h1>
          <label variant="caption" className={"userListdescription"}>
            List of all clientsxzxczxc
          </label>
          <ExportClientData
            data={exportData}
            button={
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "160px",
                  height: "45px",
                  color: "white",
                  marginTop: "28px",
                  marginBottom: "28px",
                  position: "absolute",
                  right: "200px",
                  top: "80px",
                  textTransform: "capitalize",
                }}
              >
                Export All Data
              </Button>
            }
            filename={`User list - ${moment().format("MM/DD/YY - hh:mm a")}`}
          />
          <Button
            variant="contained"
            className={"userListaddButton"}
            component={Link}
            to={ROUTES.ADD_USER}
          >
            Add New Admin
          </Button>
          <br /> <br /> <br /> <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomDataTable
                data={clientList}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UserListForm;
