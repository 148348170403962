import React, { useState } from "react";

import "./css/fundAddEdit.css";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import {
  TextField,
  Fade,
  FormHelperText,
  Button,
  CircularProgress,
  Snackbar,
  Divider,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  ButtonGroup,
  FormLabel,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import MuiAlert from "@material-ui/lab/Alert";

import { withStyles } from "@material-ui/core/styles";

import UploadFile from "./UploadFile";

import Documents from "./Documents";
import { ChromePicker } from "react-color";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: "#256141",
    "& .MuiChip-label": {
      color: "#fff",
      "@media only screen and (max-width: 1500px)": {
        fontSize: "12px !important",
      },
      "@media only screen and (max-width: 1366px)": {
        fontSize: "10px !important",
      },
    },
    "& .MuiChip-deleteIcon": {
      color: "#fff",
    },
  },
  input: {
    padding: "10px !important",
    fontSize: "16px !important",
    "@media only screen and (max-width: 1500px)": {
      padding: "5px !important",
      fontSize: "14px !important",
    },
    "@media only screen and (max-width: 1366px)": {
      padding: "3px !important",
      fontSize: "12px !important",
    },
  },
})(Autocomplete);

const GreenRadio = withStyles({
  root: {
    color: "#256141",
    "&$checked": {
      color: "#256141",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const GreenSwitch = withStyles({
  root: {
    float: "right",
    marginTop: -10,
  },
  switchBase: {
    color: "#24975a",
    "&$checked": {
      color: "#256141",
    },
    "&$checked + $track": {
      backgroundColor: "#256141",
    },
  },
  checked: {},
  track: {},
})(Switch);

const FundAddEditForm = (props) => {
  const {
    action,
    categories,
    companies,
    categoriesSelected,
    fundCode,
    fundTitle,
    fundCompany,
    fundRiskType,
    fundRateType,
    fundOverview,
    fundStatus,
    fundType,
    fundManager,
    fundCurrency,
    fundMinimumInitial,
    fundMinimumAditional,
    fundLengthStay,
    fundInceptionDate,
    fundManagementFee,
    fundEntryFee,
    fundHoldingPeriod,
    fundEarlyRedemption,
    fundColor,
    fundCodeError,
    fundTitleError,
    fundCategoriesError,
    fundOverviewError,
    fundManagerError,
    fundCurrencyError,
    fundMinimumInitialError,
    fundMinimumAditionalError,
    fundLengthStayError,
    fundInceptionDateError,
    fundManagementFeeError,
    fundEntryFeeError,
    fundHoldingPeriodError,
    fundEarlyRedemptionError,
    fundColorError,
    toggleAlert,
    messageType,
    messageAlert,
    toggleActionAlert,
    isLoading,
    files,
    existingFiles,
    bannerEnabled,
    bannerColor,
    bannerText,
    bannerTextColor,
    marker
  } = props.state;

  const [copyAlert, setCopyAlert] = useState(false);

  const handleCopyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopyAlert(true);
      })
      .catch((err) => {
        console.error('Unable to copy text to clipboard', err);
      });
  };

  return (
    <>
      <form onSubmit={props.onSubmit()}>
        <div className={"fundEditRoot"}>
          {toggleAlert && (
            <Alert onClose={props.onhandleClose()} severity={messageType}>
              {messageAlert}
            </Alert>
          )}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={"fundEditContainerLeft"}>
              <label className={"fundEditHeader"}>{`${action} Fund`}</label>

              <label className={"fundEditHeader2"}>Overview</label>

              <br />
              <Divider />

              <br />
              <br />
              <label className={"fundEditTitle"}>Fund Code</label>

              <TextField
                name="fundCode"
                style={{ textTransform: "uppercase" }}
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundCode}
                onChange={props.onChange()}
                placeholder="Fund code..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundCodeError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Fund Name</label>

              <TextField
                name="fundTitle"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundTitle}
                onChange={props.onChange()}
                placeholder="Fund title..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundTitleError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Company</label>

              <FormControl
                variant="outlined"
                size="small"
                className={"fundEditFormControl"}
              >
                <Select
                  native
                  name="fundCompany"
                  value={fundCompany}
                  onChange={props.onhandleSelectChange()}
                >
                  {companies.map((item, index) => (
                    <option key={item.companyId} value={index}>
                      {item.companyName}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <br />
              <label className={"fundEditTitle"}>Categories</label>
              <CustomAutocomplete
                value={categoriesSelected.map((item) => categories[item])}
                name="fundCategories"
                multiple
                id="tags-outlined"
                options={categories}
                getOptionLabel={(option) => option.categoryName}
                filterSelectedOptions
                onChange={props.onHandleCategorySelected()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search category name..."
                  />
                )}
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundCategoriesError}
                </FormHelperText>
              </Fade>
              <br />
              <label className={"fundEditTitle"}>Marker (optional)</label>
              <FormControl component="fieldset">
                <RadioGroup
                  name="fundMaker"
                  style={{ display: "flex", flexDirection: "row" }}
                  value={marker}
                  onChange={props.onHandleChangeMaker()}
                >
                  <FormControlLabel classes={{ label: "fundEditRadioButtonText" }}  value="MF" control={<GreenRadio />} label="Mutual Fund" />
                  <FormControlLabel classes={{ label: "fundEditRadioButtonText" }}  value="UITF" control={<GreenRadio />} label="UITF" /> 
                  {marker !== "" && <Button variant="contained" style={{ color: "white", backgroundColor:"#256141" }}  onClick={() => props.onHandleChangeMaker()({ target: { value: "" } })}>Clear</Button>}
                </RadioGroup>
                
                {fundCategoriesError && (
                  <Fade in={true}>
                    <FormHelperText style={{ color: "red" }}>{fundCategoriesError}</FormHelperText>
                  </Fade>
                )}
              </FormControl>

              <br />
              <label className={"fundEditTitle"}>Risk Type</label>

              <FormControl
                variant="outlined"
                size="small"
                className={"fundEditFormControl"}
              >
                <Select
                  native
                  name="fundRiskType"
                  value={fundRiskType}
                  onChange={props.onhandleSelectChange()}
                >
                  {["Conservative", "Moderate", "Aggressive"].map(
                    (item, index) => (
                      <option key={item} value={index}>
                        {item}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>

              <br />
              <label className={"fundEditTitle"}>Rate Type</label>

              <RadioGroup
                aria-label="fundRateType"
                name="fundRateType"
                value={fundRateType}
                style={{ display: "flex", flexDirection: "row" }}
                onChange={props.onhandleRateType()}
              >
                <FormControlLabel
                  value={0}
                  className={
                    fundRateType === 0
                      ? "fundEditRadioButtonActive"
                      : "fundEditRadioButton"
                  }
                  control={<GreenRadio />}
                  label="Fixed"
                  classes={{ label: "fundEditRadioButtonText" }}
                />
                <FormControlLabel
                  value={1}
                  className={
                    fundRateType === 1
                      ? "fundEditRadioButtonActive"
                      : "fundEditRadioButton"
                  }
                  control={<GreenRadio />}
                  label="Variable"
                  classes={{ label: "fundEditRadioButtonText" }}
                />
              </RadioGroup>

              <br />
              <br />
              <label className={"fundEditTitle"}>Fund Overview</label>

              <TextField
                name="fundOverview"
                inputProps={{ className: "fundEditDescription" }}
                variant="outlined"
                value={fundOverview}
                onChange={props.onChange()}
                placeholder="Fund overview..."
                autoComplete="off"
                multiline
                rows={8}
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundOverviewError}
                </FormHelperText>
              </Fade>

              <br />
              <br />
              <Divider />

              <div style={{ alignItems: "center" }}>
                <br />
                <label className={"fundEditTitle"}>Active Status</label>

                <GreenSwitch
                  checked={fundStatus}
                  onChange={props.onhandleStatusChange()}
                  color="secondary"
                  name="fundStatus"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                // classes={{root: 'fundEditStatusAlign'}}
                />
                <br />
                <br />
              </div>
              <Divider />
              <br />
              <br />
              <label className={"fundEditTitle"}>
                Add New Supporting Documents
              </label>
              <UploadFile
                fileChange={props.fileChange}
                files={files}
                deleteFile={props.deleteFile}
                action={action}
                maxSize="10mb"
                supported="All"
              />
              {action === "Edit" && (
                <div>
                  <br />
                  <label className={"fundEditTitle"}>Existing Documents</label>
                  <Documents
                    files={existingFiles}
                    deleteFile={props.deleteExistingFile}
                  />
                </div>
              )}
            </div>
            <div className={"fundEditContainerRight"}>
              <label className={"fundEditHeader2"}>Details</label>

              <br />
              <Divider />

              <br />
              <br />
              <label className={"fundEditTitle"}>Mutual Fund Type</label>

              <FormControl
                variant="outlined"
                size="small"
                className={"fundEditFormControl"}
              >
                <Select
                  native
                  name="fundType"
                  value={fundType}
                  onChange={props.onhandleSelectChange()}
                >
                  {[
                    "Money Market Fund",
                    "Bond Fund",
                    "Balanced Fund",
                    "Stock Fund",
                  ].map((item, index) => (
                    <option key={item} value={index}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <br />
              <label className={"fundEditTitle"}>Fund Manager</label>

              <TextField
                name="fundManager"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundManager}
                onChange={props.onChange()}
                placeholder="Fund manager..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundManagerError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Currency</label>

              <TextField
                name="fundCurrency"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundCurrency}
                onChange={props.onChange()}
                placeholder="Currency..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundCurrencyError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>
                Minimum Initial Investment (in Pesos)
              </label>

              <TextField
                name="fundMinimumInitial"
                inputProps={{
                  className: "fundEditTextBox"
                }}
                variant="outlined"
                value={fundMinimumInitial}
                onChange={props.onChange()}
                placeholder="Minimum initial investment..."
                autoComplete="off"
                required
                type="number"
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundMinimumInitialError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>
                Minimum Additional Investment (in Pesos)
              </label>

              <TextField
                name="fundMinimumAditional"
                inputProps={{
                  className: "fundEditTextBox"
                }}
                variant="outlined"
                value={fundMinimumAditional}
                onChange={props.onChange()}
                placeholder="Minimum additional investment..."
                autoComplete="off"
                required
                type="number"
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundMinimumAditionalError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>
                Recommended Length of Stay
              </label>

              <TextField
                name="fundLengthStay"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundLengthStay}
                onChange={props.onChange()}
                placeholder="Recommended length of stay..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundLengthStayError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Inception Date</label>

              <TextField
                type="date"
                name="fundInceptionDate"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundInceptionDate}
                onChange={props.onChange()}
                placeholder="Inception date..."
                autoComplete="off"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundInceptionDateError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Management Fee</label>

              <TextField
                name="fundManagementFee"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundManagementFee}
                onChange={props.onChange()}
                onCut={props.onCopyPasteCut()}
                onCopy={props.onCopyPasteCut()}
                onPaste={props.onCopyPasteCut()}
                placeholder="Management fee..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundManagementFeeError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Entry Fee</label>

              <TextField
                name="fundEntryFee"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundEntryFee}
                onChange={props.onChange()}
                onCut={props.onCopyPasteCut()}
                onCopy={props.onCopyPasteCut()}
                onPaste={props.onCopyPasteCut()}
                placeholder="Entry fee..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundEntryFeeError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Holding Period</label>

              <TextField
                name="fundHoldingPeriod"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundHoldingPeriod}
                onChange={props.onChange()}
                placeholder="Holding period..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundHoldingPeriodError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Early Redemption Fee</label>

              <TextField
                name="fundEarlyRedemption"
                inputProps={{ className: "fundEditTextBox" }}
                variant="outlined"
                value={fundEarlyRedemption}
                onChange={props.onChange()}
                onCut={props.onCopyPasteCut()}
                onCopy={props.onCopyPasteCut()}
                onPaste={props.onCopyPasteCut()}
                placeholder="Early redemption..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundEarlyRedemptionError}
                </FormHelperText>
              </Fade>

              <br />
              <label className={"fundEditTitle"}>Assigned Fund Color</label>

              <ChromePicker
                name="fundColor"
                color={fundColor}
                onChange={props.onColorChange()}
              />

              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {fundColorError}
                </FormHelperText>
              </Fade>

              <br />
              <br />
              <Divider />
              <div style={{ alignItems: "center" }}>
                <br />
                <label className={"fundEditTitle"}>Enable Banner</label>

                <GreenSwitch
                  checked={bannerEnabled}
                  onChange={props.onhandleBannerStatusChange()}
                  color="secondary"
                  name="bannerEnabled"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <br />
                <br />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label className={"fundEditTitle"}>Banner Background Color</label>
                    <ChromePicker
                      name="bannerColor"
                      color={bannerColor}
                      onChange={props.onBannerColorChange()}
                    />
                    <Fade in={true}>
                      <FormHelperText style={{ color: "red" }}>
                        {fundColorError}
                      </FormHelperText>
                    </Fade>
                  </div>
                  <div>
                    <label className={"fundEditTitle"}>Banner Text Color</label>
                    <ChromePicker
                      name="bannerTextColor"
                      color={bannerTextColor}
                      onChange={props.onBannerColorTextChange()}
                    />
                    <Fade in={true}>
                      <FormHelperText style={{ color: "red" }}>
                        {fundColorError}
                      </FormHelperText>
                    </Fade>
                  </div>
                </div>

                <br />
                <label className={"fundEditTitle"}>Rampver Colors - Copy to clipboard</label>
                <div style={{ display: 'flex', gap: '2px' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCopyToClipboard('#C8932A')}
                  >
                    Rampver Gold
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ color: '#256141' }}
                    onClick={() => handleCopyToClipboard('#256141')}
                  >
                    Rampver Green
                  </Button>
                </div>
              </div>
              <br />
              <label className={"fundEditTitle"}>Text to display</label>
              <TextField
                name="bannerText"
                inputProps={{ className: "fundEditTextBox", maxLength: 15 }}
                variant="outlined"
                value={bannerText}
                onChange={props.onChange()}
                onCut={props.onCopyPasteCut()}
                onCopy={props.onCopyPasteCut()}
                onPaste={props.onCopyPasteCut()}
                placeholder="Banner Text"
                autoComplete="off"
                required
              />
              <span style={{ marginLeft: '8px', color: 'gray' }}>
                Maximum amount of characters is 15
              </span>
            </div>
          </div>
          <div>
            <Button
              style={{ pointerEvents: isLoading ? "none" : "all" }}
              className={"fundEditCancelButton"}
              component={Link}
              to={ROUTES.FUND_LIST}
            >
              Cancel
            </Button>
            {!isLoading ? (
              <Button type="submit" className={"fundEditAddButton"}>
                Save
              </Button>
            ) : (
              <CircularProgress className={"fundEditLoading"} />
            )}
          </div>
          <br />
        </div>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={toggleActionAlert}
        onClose={props.onhandleClose()}
      >
        <Alert onClose={props.onhandleClose()} severity={messageType}>
          {messageAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={copyAlert}
        onClose={() => setCopyAlert(false)}
      >
        <Alert onClose={() => setCopyAlert(false)} severity={'success'}>
          Copied to Clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

export default FundAddEditForm;
