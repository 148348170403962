import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";

import rampverLogo from "../../images/rampver-logo.png";
import "./css/navigation.css";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  AppBar,
  Toolbar,
  Drawer,
  CssBaseline,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Hidden,
  MenuItem,
  Collapse,
  Divider,
} from "@material-ui/core";

import MenuCore from "@material-ui/core/menu";

import {
  AccountCircleOutlined,
  AssessmentOutlined,
  Menu,
  DescriptionOutlined,
  ExpandLess,
  ExpandMore,
  ReceiptOutlined,
  DashboardOutlined,
  ShowChartOutlined,
  AssignmentIndOutlined,
} from "@material-ui/icons";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import FaceIcon from '@material-ui/icons/Face';
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    background: "white",
    fontFamily: "'Poppins', san-serif",
    height: "100%",
  },
}));

var navSelected = 0;
var expandedDrawerSelect = 0;
let drawer1Expand = false;
let drawer2Expand = false;

function Navigation(props) {
  const { window } = props;

  const classes = useStyles();
  const theme = useTheme();

  // const [isOpen, setIsOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_nav = Boolean(anchorEl);

  const handleListItemClick = (event, index) => {
    switch (event.currentTarget.dataset.drawer) {
      case "drawer1":
        drawer1Expand = drawer1Expand ? false : true;
        break;

      case "drawer2":
        drawer2Expand = drawer2Expand ? false : true;
        break;

      default:
        drawer1Expand = false;
        drawer2Expand = false;
        break;
    }

    navSelected = index;
  };

  const handleExpandedItemClick = (route, index, state) => {
    if (state !== undefined)
      props.history.push({
        pathname: route,
        search: `type=${state.type}`,
      });
    else props.history.push(route);

    expandedDrawerSelect = index;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOptionToggleOn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionToggleOff = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    props.firebase.doSignOut();
  };

  const expandDrawer1Content = (
    <>
      <List component="div" disablePadding>
        {[
          { value: "Company", route: ROUTES.COMPANY_LIST },
          { value: "Funds", route: ROUTES.FUND_LIST },
          { value: "Categories", route: ROUTES.CATEGORIES_LIST },
          { value: "Terms & Condition", route: ROUTES.CONTENT },
          { value: "Privacy Policy", route: ROUTES.PRIVACY_POLICY },
          { value: "Redemption Terms", route: ROUTES.REDEMPTION_TERMS },
        ].map((item, index) => (
          <>
            <ListItem
              button
              key={item.value}
              selected={expandedDrawerSelect === index}
              onClick={(event) => handleExpandedItemClick(item.route, index)}
            >
              <ListItemIcon className={"navmenuPadding "}></ListItemIcon>
              <ListItemText
                className={
                  expandedDrawerSelect === index
                    ? "navmenuColorActive"
                    : "navmenuColor"
                }
                classes={{ primary: "navmenuFontSize" }}
                primary={item.value}
              />
            </ListItem>
            {item.value === "Categories" ? <Divider /> : ""}
          </>
        ))}
      </List>
    </>
  );

  const expandDrawer2Content = (
    <>
      <List component="div" disablePadding>
        {[
          {
            value: "Bookings Files",
            route: ROUTES.RECENT_UPLOAD,
            state: { type: "booking" },
          },
          {
            value: "Redemption Files",
            route: ROUTES.RECENT_UPLOAD,
            state: { type: "redemption" },
          },
        ].map((item, index) => (
          <>
            <ListItem
              button
              key={item.value}
              selected={expandedDrawerSelect === index}
              onClick={(event) =>
                handleExpandedItemClick(item.route, index, item.state)
              }
            >
              <ListItemIcon className={"navmenuPadding "}></ListItemIcon>
              <ListItemText
                className={
                  expandedDrawerSelect === index
                    ? "navmenuColorActive"
                    : "navmenuColor"
                }
                classes={{ primary: "navmenuFontSize" }}
                primary={item.value}
              />
            </ListItem>
            {item.value === "Categories" ? <Divider /> : ""}
          </>
        ))}
      </List>
    </>
  );

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <img src={rampverLogo} alt="Rampver Logo" className="navdashboardLogo" />

      <List component="nav">
        {[
          {
            text: "Action Dashboard",
            drawer: "",
            route: ROUTES.DASHBOARD,
            icon: <AssignmentIndOutlined />,
            condition: false,
          },
          {
            text: "Analytics Dashboard",
            drawer: "",
            route: ROUTES.ANALYTICS_DASHBOARD,
            icon: <DashboardOutlined />,
            condition: false,
          },
          {
            text: "Clients",
            drawer: "",
            route: ROUTES.USERS,
            icon: <AccountCircleOutlined />,
            condition: false,
          },
          {
            text: "Graduating Clients",
            drawer: "",
            route: ROUTES.GRADUATING_USERS,
            icon: <FaceIcon />,
            condition: false,
          },
          {
            text: "Referrers",
            drawer: "",
            route: ROUTES.REFERRERS,
            icon: <AccountCircleOutlined />,
            condition: false,
          },
          {
            text: "Transactions List",
            drawer: "",
            route: ROUTES.TRANSACTION_LIST,
            icon: <ReceiptOutlined />,
            condition: false,
          },
          {
            text: "Recurring Transactions List",
            drawer: "",
            route: ROUTES.RECURRING_TRANSACTIONS,
            icon: <NewReleasesOutlinedIcon />,
            condition: false,
          },
          {
            text: "Recurring Investments List",
            drawer: "",
            route: ROUTES.RECURRING_INVESTMENTS,
            icon: <AssessmentOutlined />,
            condition: false,
          },
          {
            text: "NAVPS History",
            drawer: "",
            route: ROUTES.NAVPS_EDIT,
            icon: <ShowChartOutlined />,
            condition: false,
          },
          {
            text: "Manage Content",
            drawer: "drawer1",
            route: "#manage-content",
            icon: <DescriptionOutlined />,
            condition: true,
          },
          {
            text: "Fund Reinvestment",
            drawer: "",
            route: ROUTES.FUND_REINVESTMENT,
            icon: <FeedbackOutlinedIcon />,
            condition: false,
          },
          {
            text: "Upload Booking",
            drawer: "",
            route: ROUTES.INVESTMENT_REQUEST_UPLOAD,
            icon: <CloudUploadIcon />,
            condition: false,
          },
          {
            text: "Upload Redemption",
            drawer: "",
            route: ROUTES.WITHDRAWAL_REQUEST_UPLOAD,
            icon: <CloudUploadIcon />,
            condition: false,
          },
          {
            text: "Recently Uploaded Files",
            drawer: "drawer2",
            route: "#recent-files",
            icon: <DescriptionOutlined />,
            condition: true,
          },
          {
            text: "View Feedback",
            drawer: "",
            route: ROUTES.FEEDBACK,
            icon: <FeedbackOutlinedIcon />,
            condition: false,
          },
          {
            text: "Audit Logs",
            drawer: "",
            route: ROUTES.AUDIT_LOGS,
            icon: <VerifiedUserOutlinedIcon />,
            condition: false,
          },
          // {
          //   text: "TRC Batch Upload",
          //   drawer: "",
          //   route: ROUTES.TRC,
          //   icon: <FeedbackOutlinedIcon />,
          //   condition: false,
          // },
        ].map((item, index) => (
          <>
            <ListItem
              button
              key={item.text}
              data-drawer={item.drawer}
              selected={navSelected === index}
              onClick={(event) => handleListItemClick(event, index)}
              component={Link}
              to={item.route}
            >
              <ListItemIcon
                className={
                  navSelected === index
                    ? "navmenuColorActive navmenuPadding"
                    : "navmenuColor navmenuPadding"
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                className={
                  navSelected === index ? "navmenuColorActive" : "navmenuColor"
                }
                classes={{ primary: "navmenuFontSize" }}
                primary={item.text}
              />
              {item.condition ? (
                drawer1Expand ? (
                  <ExpandLess
                    className={
                      navSelected === index
                        ? "navmenuColorActive"
                        : "navmenuColor"
                    }
                  />
                ) : (
                  <ExpandMore
                    className={
                      navSelected === index
                        ? "navmenuColorActive"
                        : "navmenuColor"
                    }
                  />
                )
              ) : (
                ""
              )}
            </ListItem>

            <Collapse in={drawer1Expand} timeout="auto" unmountOnExit>
              {item.drawer === "drawer1" && expandDrawer1Content}
            </Collapse>

            <Collapse in={drawer2Expand} timeout="auto" unmountOnExit>
              {item.drawer === "drawer2" && expandDrawer2Content}
            </Collapse>
          </>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    // <StylesProvider injectFirst>
    <div className={"navroot"}>
      <CssBaseline />
      <AppBar position="fixed" className={"navappBar"}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={"navmenuButton"}
          >
            <Menu />
          </IconButton>
          <IconButton
            aria-label="account"
            className={"navavatar"}
            edge="end"
            onClick={handleOptionToggleOn}
          >
            <AccountCircleOutlined />
          </IconButton>
          <MenuCore
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open_nav}
            onClose={handleOptionToggleOff}
          >
            <MenuItem component={Link} to={ROUTES.ACCOUNT}>
              Account
            </MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </MenuCore>
          {/* <Avatar className={"navavatar"}>E</Avatar> */}
        </Toolbar>
      </AppBar>
      <nav className={"navdrawer"} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: "navdrawerPaper2",
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden implementation="css">
          <Drawer
            classes={{
              paper: "navdrawerPaper",
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content} color="inherit">
        <div className={classes.toolbar} />
        {props.content}
      </main>
    </div>
    // </StylesProvider>
  );
}

export default withFirebase(withRouter(Navigation));
