import axios from "axios";
import * as ROUTES from "../../constants/routes";
import Helpers from "../Helpers";

export default class Functions {
  constructor() {
    this.helpers = new Helpers();
  }

  doUploadFile = (excelFile, email, type) => {
    const token = this.helpers.getCookie("token");

    let url = "";

    if (type === "approval") {
      // url = `http://localhost:5001/dip-nonprod/asia-east2/operations-operations/uploadBooking`;
      url = `${ROUTES.FUNCTIONAL_BASE_URL}operations-operations/uploadBooking`;
    }

    if (type === "withdrawal") {
      url = `${ROUTES.FUNCTIONAL_BASE_URL}operations-operations/uploadWithdrawal`;
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("excel", excelFile.file);
      formData.append("req", JSON.stringify({ email: email }));
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doChangePaymentValidity = (validObj) => {
    const token = this.helpers.getCookie("token");
    console.log(validObj);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ROUTES.FUNCTIONAL_BASE_URL}operations-operations/updatePaymentValidation`,
          JSON.stringify(validObj),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
