import React, { Component } from "react";

import FundAddEditForm from "./fundAddEditFront";
import FundAddEditFunc from "./fundAddEditFunc";

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import moment from "moment";
import Helpers from "../../../Helpers";

const INITIAL_STATE = {
  action: "Add",
  categories: [],
  companies: [],
  categoriesSelected: [],
  companySelected: "",
  fundCode: "",
  fundTitle: "",
  fundCompany: 0,
  fundCategories: [],
  fundRiskType: 0,
  fundRateType: 0,
  fundOverview: "",
  fundStatus: true,
  fundType: 0,
  fundManager: "",
  fundCurrency: "",
  fundMinimumInitial: "",
  fundMinimumAditional: "",
  fundLengthStay: "",
  fundInceptionDate: moment(new Date()).format("YYYY-MM-DD"),
  fundManagementFee: "",
  fundEntryFee: "",
  fundHoldingPeriod: "",
  fundEarlyRedemption: "",
  fundColor: "#FFFFFF",
  fundCodeError: "",
  fundTitleError: "",
  fundCategoriesError: "",
  fundOverviewError: "",
  fundTypeError: "",
  fundManagerError: "",
  fundCurrencyError: "",
  fundMinimumInitialError: "",
  fundMaximumInitialError: "",
  fundMinimumAditionalError: "",
  fundLengthStayError: "",
  fundInceptionDateError: "",
  fundManagementFeeError: "",
  fundEntryFeeError: "",
  fundHoldingPeriodError: "",
  fundEarlyRedemptionError: "",
  fundColorError: "",
  isLoading: false,
  toggleAlert: false,
  messageType: "warning",
  messageAlert: "",
  toggleActionAlert: false,
  files: null,
  existingFiles: null,
  toDeleteExistingDoc: [],
  bannerColor: '#C8932A',
  bannerEnabled: false,
  bannerText: '',
  bannerTextColor: '#FFFFFF',
  marker:""
};

class FundEditFormBase extends Component {
  constructor(props) {
    super(props);

    this.helpers = new Helpers();
    this.state = { ...INITIAL_STATE };
    this.fundAddEditFunction = new FundAddEditFunc();
    this.db = firebase.firestore();
    this.fundRef = this.db.collection("funds");
    this.catRef = this.db.collection("fundCategories");
    this.compRef = this.db.collection("fundCompanies").orderBy("name", "asc");
  }

  async componentDidMount() {
    var fundId = this.props.location.state ? this.props.location.state.id : -1;

    await this.getCompanies();
    await this.getCategories();

    if (fundId !== -1) {
      this.setState({ action: "Edit" });
      await this.getFundDetails(fundId);
    }
  }

  async getCompanies() {
    await this.compRef
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          this.setState({
            toggleActionAlert: true,
            messageType: "error",
            messageAlert: "Unable to retrieve companies.",
          });
        } else {
          this.setState({
            companies: querySnapshot.docs.map((doc) => {
              return {
                companyId: doc.id,
                companyName: doc.data().name,
              };
            }),
          });
        }
      })
      .catch((error) => {
        this.setState({
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  }

  async getCategories() {
    await this.catRef
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          this.setState({
            toggleActionAlert: true,
            messageType: "error",
            messageAlert: "Unable to retrieve categories.",
          });
        } else {
          this.setState({
            categories: querySnapshot.docs.map((doc) => {
              return {
                categoryId: doc.id,
                categoryCode: doc.data().code,
                categoryName: doc.data().name,
              };
            }),
          });
        }
      })
      .catch((error) => {
        this.setState({
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  }

  async getFundDetails(fundId) {
    await this.fundRef
      .doc(fundId)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          const fundData = doc.data();
          var index = await this.parseSelectedCategories(
            this.checkTaggedCategories(
              this.state.categories,
              fundData.categories
            )
          );
          var index2 = await this.getSelectedCompany(
            fundData.fundCompanyDetails
          );

          var riskTypeInt = "";
          var fundTypeInt = "";

          switch (fundData.riskType) {
            case "conservative":
              riskTypeInt = 0;
              break;
            case "moderate":
              riskTypeInt = 1;
              break;
            case "aggressive":
              riskTypeInt = 2;
              break;
            default:
              break;
          }

          switch (fundData.mutualFundType.toLowerCase()) {
            case "money market funds":
              fundTypeInt = 0;
              break;
            case "bond funds":
              fundTypeInt = 1;
              break;
            case "balanced funds":
              fundTypeInt = 2;
              break;
            case "stock funds":
              fundTypeInt = 3;
              break;
            default:
              break;
          }
          let fundOverview = this.helpers.stringToWhiteSpaceConverter(
            fundData.overview
          );
          var rateTypeInt = fundData.rateType === "fixed" ? 0 : 1;
          this.setState({
            categoriesSelected: index,
            companySelected: index2,
            fundCode: fundData.code,
            fundTitle: fundData.name,
            fundCompany: index2,
            fundCategories: this.checkTaggedCategories(
              this.state.categories,
              fundData.categories
            ),
            fundRiskType: riskTypeInt,
            fundRateType: rateTypeInt,
            fundOverview: fundOverview,
            fundStatus: fundData.status,
            fundType: fundTypeInt,
            fundManager: fundData.fundManager,
            fundCurrency: fundData.currency,
            fundMinimumInitial: fundData.minInitialInvestment,
            fundMinimumAditional: fundData.minAdditionalInvestment,
            fundLengthStay: fundData.recommendedLength,
            fundInceptionDate: new Date(
              fundData.inceptionDate
            ).toLocaleDateString("en-CA"),
            fundManagementFee: fundData.managementFee,
            fundEntryFee: fundData.entryFee,
            fundHoldingPeriod: fundData.holdingPeriod,
            fundEarlyRedemption: fundData.redemptionFee,
            fundColor: fundData.color,
            existingFiles:
              fundData.supportingDocuments.length !== 0
                ? fundData.supportingDocuments
                : null,
            toDeleteExistingDoc: [],
            bannerColor: fundData.banner?.color || INITIAL_STATE.bannerColor,
            bannerEnabled: fundData.banner?.enabled || INITIAL_STATE.bannerEnabled,
            bannerText: fundData.banner?.text || INITIAL_STATE.bannerText,
            bannerTextColor: fundData.banner?.textColor || INITIAL_STATE.bannerTextColor,
            marker: fundData.fundType || INITIAL_STATE.marker
          });
        }
      })
      .catch((err) => {
        this.setState({
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: err.message,
        });
      });
  }

  getSelectedCompany(company) {
    return this.state.companies.findIndex(
      (x) => x.companyId === company.fundCompanyId
    );
  }

  checkTaggedCategories(array1, array2) {
    const arrayToReturn = [];

    array1.forEach((item1) => {
      array2.forEach((item2) => {
        if (item1.categoryCode === item2) {
          arrayToReturn.push(item1.categoryCode);
        }
      });
    });
    return arrayToReturn;
  }

  parseSelectedCategories(catIds) {
    const indexs = [];
    catIds.forEach((items) => {
      var catIndex = this.state.categories.findIndex(
        (x) => x.categoryCode === items
      );
      catIndex !== -1 && indexs.push(catIndex);
    });

    return indexs;
  }

  handleCategorySelected = (event, values) => {
    const selectedCategories = [];

    values.map((item) => selectedCategories.push(item.categoryCode));

    var ids = this.parseSelectedCategories(selectedCategories);

    this.setState(
      {
        fundCategories: selectedCategories,
        categoriesSelected: ids,
      },
      () => {
        this.categoriesValidator();
      }
    );
  };
  handleChangeMaker = (event) => {
    this.setState({marker:event.target.value})
  }

  categoriesValidator() {
    if (this.state.fundCategories.length < 1) {
      this.setState({
        fundCategoriesError: "Please select at least one (1) fund",
      });
    } else {
      this.setState({ fundCategoriesError: "" });
    }
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validator(name);
    });
  };

  handleColorChange = (event) => {
    this.setState({ fundColor: event.hex });
  };

  handleBannerColorChange = (event) => {
    this.setState({ bannerColor: event.hex });
  };

  handleBannerColorTextChange = (event) => {
    this.setState({ bannerTextColor: event.hex });
  };

  validator = (name) => {
    const {
      fundCode,
      fundTitle,
      fundOverview,
      fundType,
      fundManager,
      fundCurrency,
      fundMinimumInitial,
      fundMinimumAditional,
      fundLengthStay,
      fundInceptionDate,
      fundManagementFee,
      fundEntryFee,
      fundHoldingPeriod,
      fundEarlyRedemption,
      fundColor,
    } = this.state;

    switch (name) {
      case "fundCode":
        if (fundCode.trim() === "") {
          this.setState({ fundCodeError: "Please input fund code." });
        } else {
          this.setState({ fundCodeError: "" });
        }
        break;
      case "fundTitle":
        if (fundTitle.trim() === "") {
          this.setState({ fundTitleError: "Please input fund title." });
        } else {
          this.setState({ fundTitleError: "" });
        }
        break;
      case "fundOverview":
        if (fundOverview.trim() === "") {
          this.setState({ fundOverviewError: "Please input fund overview." });
        } else {
          this.setState({ fundOverviewError: "" });
        }
        break;
      case "fundType":
        if (fundType.trim() === "") {
          this.setState({ fundTypeError: "Please input fund type." });
        } else {
          this.setState({ fundTypeError: "" });
        }
        break;
      case "fundManager":
        if (fundManager.trim() === "") {
          this.setState({ fundManagerError: "Please input fund manager." });
        } else {
          this.setState({ fundManagerError: "" });
        }
        break;
      case "fundCurrency":
        if (fundCurrency.trim() === "") {
          this.setState({ fundCurrencyError: "Please input fund currency." });
        } else {
          this.setState({ fundCurrencyError: "" });
        }
        break;
      case "fundMinimumInitial":
        if (fundMinimumInitial.trim().length < 1) {
          this.setState({
            fundMinimumInitialError:
              "Please input fund valid minimum initial investment.",
          });
        } else {
          this.setState({ fundMinimumInitialError: "" });
        }
        break;
      case "fundMinimumAditional":
        if (fundMinimumAditional.trim().length < 1) {
          this.setState({
            fundMinimumAditionalError:
              "Please input fund valid minimum additional investment.",
          });
        } else {
          this.setState({ fundMinimumAditionalError: "" });
        }
        break;
      case "fundLengthStay":
        if (fundLengthStay.trim() === "") {
          this.setState({
            fundLengthStayError: "Please input fund recomended length of stay.",
          });
        } else {
          this.setState({ fundLengthStayError: "" });
        }
        break;
      case "fundInceptionDate":
        if (fundInceptionDate.trim() === "") {
          this.setState({
            fundInceptionDateError: "Please input valid fund inception date.",
          });
        } else {
          this.setState({ fundInceptionDateError: "" });
        }
        break;
      case "fundManagementFee":
        if (fundManagementFee.length < 1) {
          this.setState({
            fundManagementFeeError: "Please input valid management fee.",
          });
        } else {
          this.setState({ fundManagementFeeError: "" });
        }
        break;
      case "fundEntryFee":
        if (fundEntryFee.length < 1) {
          this.setState({ fundEntryFeeError: "Please input valid entry fee." });
        } else {
          this.setState({ fundEntryFeeError: "" });
        }
        break;
      case "fundHoldingPeriod":
        if (fundHoldingPeriod.trim() === "") {
          this.setState({
            fundHoldingPeriodError: "Please input holding period.",
          });
        } else {
          // if (Number(fundHoldingPeriod) > 12) {
          //     this.setState({ fundHoldingPeriodError: "Holding period should not be greater than 12." });
          // } else {
          this.setState({ fundHoldingPeriodError: "" });
          //}
        }
        break;
      case "fundEarlyRedemption":
        if (fundEarlyRedemption.length < 1) {
          this.setState({
            fundEarlyRedemptionError:
              "Please input valid early redemption fee.",
          });
        } else {
          this.setState({ fundEarlyRedemptionError: "" });
        }
        break;
      case "fundColor":
        if (fundColor.length < 1) {
          this.setState({ fundColorError: "Please input a fund color." });
        } else {
          this.setState({ fundColorError: "" });
        }
        break;
      default:
        break;
    }
  };

  onMobileNumKeyUp = (event) => {
    const newVal = event.target.value.replace(/[^\d]/, "");
    this.setState(
      {
        [event.target.name]: newVal,
      },
      () => this.validator(event)
    );
  };

  handleSelectChange = (event) => {
    this.setState({
      [event.target.name]: Number(event.target.value),
    });
  };

  onSubmit = (event) => {
    var fundId = this.props.location.state ? this.props.location.state.id : -1;
    event.preventDefault();

    this.setState({ isLoading: true });

    const {
      companies,
      fundCode,
      fundTitle,
      fundCategories,
      fundCompany,
      fundRiskType,
      fundRateType,
      fundOverview,
      fundStatus,
      fundType,
      fundManager,
      fundCurrency,
      fundMinimumInitial,
      fundMinimumAditional,
      fundLengthStay,
      fundInceptionDate,
      fundManagementFee,
      fundEntryFee,
      fundHoldingPeriod,
      fundEarlyRedemption,
      fundColor,
      fundCodeError,
      fundTitleError,
      fundCategoriesError,
      fundOverviewError,
      fundManagerError,
      fundCurrencyError,
      fundMinimumInitialError,
      fundMaximumInitialError,
      fundMinimumAditionalError,
      fundLengthStayError,
      fundInceptionDateError,
      fundManagementFeeError,
      fundEntryFeeError,
      fundHoldingPeriodError,
      fundEarlyRedemptionError,
      fundColorError,
      files,
      toDeleteExistingDoc,
      bannerColor,
      bannerEnabled,
      bannerText,
      bannerTextColor,
      marker
    } = this.state;

    var riskTypeStr = "";
    var fundTypeStr = "";
    var selectedComp = companies[fundCompany];

    switch (fundRiskType) {
      case 0:
        riskTypeStr = "conservative";
        break;
      case 1:
        riskTypeStr = "moderate";
        break;
      case 2:
        riskTypeStr = "aggressive";
        break;
      default:
        break;
    }

    switch (fundType) {
      case 0:
        fundTypeStr = "Money Market Funds";
        break;
      case 1:
        fundTypeStr = "Bond Funds";
        break;
      case 2:
        fundTypeStr = "Balanced Funds";
        break;
      case 3:
        fundTypeStr = "Stock Funds";
        break;
      default:
        break;
    }

    var rateTypeStr = fundRateType === 0 ? "fixed" : "variable";

    const funds = {
      fundTitle: fundTitle.trim(),
      fundCode: fundCode.trim(),
      selectedComp: selectedComp,
      fundCategories: fundCategories,
      fundOverview: fundOverview,
      fundManager: fundManager,
      riskTypeStr: riskTypeStr,
      fundCurrency: fundCurrency,
      fundMinimumInitial: fundMinimumInitial,
      fundMinimumAditional: fundMinimumAditional,
      fundInceptionDate: fundInceptionDate,
      fundManagementFee: fundManagementFee,
      fundLengthStay: fundLengthStay,
      fundEntryFee: fundEntryFee,
      fundHoldingPeriod: fundHoldingPeriod,
      fundEarlyRedemption: fundEarlyRedemption,
      fundColor: fundColor,
      fundTypeStr: fundTypeStr,
      fundStatus: fundStatus,
      rateTypeStr: rateTypeStr,
      documentsToDelete: toDeleteExistingDoc,
      bannerColor,
      bannerEnabled,
      bannerText,
      bannerTextColor,
      marker
    };

    const isValid =
      fundCodeError === "" &&
      fundTitleError === "" &&
      fundCategoriesError === "" &&
      fundCategories.length > 0 &&
      fundOverviewError === "" &&
      fundManagerError === "" &&
      fundCurrencyError === "" &&
      fundMinimumInitialError === "" &&
      fundMaximumInitialError === "" &&
      fundMinimumAditionalError === "" &&
      fundLengthStayError === "" &&
      fundInceptionDateError === "" &&
      fundManagementFeeError === "" &&
      fundEntryFeeError === "" &&
      fundHoldingPeriodError === "" &&
      fundEarlyRedemptionError === "" &&
      fundColorError === "";
console.log(funds)
    if (isValid) {
      if (fundId === -1) {
        this.fundAddEditFunction
          .doFundAdd(funds, files)
          .then(() => {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "success",
              messageAlert: "Fund added successfully.",
            });

            setTimeout(() => {
              this.props.history.push(ROUTES.FUND_LIST);
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "error",
              messageAlert: error.message,
            });
          });
      } else {
        this.fundAddEditFunction
          .doFundEdit(fundId, funds, files)
          .then(() => {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "success",
              messageAlert: "Fund updated successfully.",
            });
            setTimeout(() => {
              this.props.history.push(ROUTES.FUND_LIST);
            }, 1000);
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "error",
              messageAlert: error.message,
            });
          });
      }
    } else {
      this.setState({
        isLoading: false,
        toggleActionAlert: true,
        messageType: "error",
        messageAlert: "Check all fields before proceeding.",
      });
    }
  };

  onhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toggleActionAlert: false });
  };

  onhandleRateType = (event) => {
    this.setState({ [event.target.name]: Number(event.target.value) });
  };

  onhandleStatusChange = (event) => {
    this.setState({ fundStatus: !this.state.fundStatus });
  };

  onhandleBannerStatusChange = (event) => {
    this.setState({ bannerEnabled: !this.state.bannerEnabled });
  };

  onCopyPasteCut = (event) => {
    event.preventDefault();
  };

  fileChange = (event) => {
    var files = event.target.files;
    var mbPerFile = 10 * 1024 * 1024;
    let totalSize = 0;

    for (var a = 0; a < files.length; a++) {
      totalSize += files[a].size;
    }

    if (files.length > 5) {
      this.setState({
        toggleActionAlert: true,
        messageType: "warning",
        messageAlert:
          "Only 5 files is allowed per single upload. You may edit the fund after the initial upload.",
      });
    } else {
      if (totalSize >= mbPerFile) {
        this.setState({
          toggleActionAlert: true,
          messageType: "warning",
          messageAlert: "Exceeded 10mb per file upload.",
        });
      } else {
        this.setState({ files: files });
      }
    }
  };

  deleteFile = (filename) => {
    var files = [...this.state.files];
    for (var a = 0; a < files.length; a++) {
      if (filename === files[a].name) {
        files.splice(a, 1);
      }
    }
    this.setState({ files: files.length === 0 ? null : files });
  };

  deleteExistingFile = (filename) => {
    var files = this.state.existingFiles;
    var toDelete = this.state.toDeleteExistingDoc;
    for (var a = 0; a < files.length; a++) {
      if (filename === files[a].split("/")[2]) {
        toDelete.push(files[a]);
        files.splice(a, 1);
      }
    }
    this.setState({
      existingFiles: files.length === 0 ? null : files,
      toDeleteExistingDoc: toDelete,
    });
  };

  render() {
    return (
      <FundAddEditForm
        state={this.state}
        onChange={() => this.handleInputChange.bind(this)}
        onColorChange={() => this.handleColorChange.bind(this)}
        onBannerColorChange={() => this.handleBannerColorChange.bind(this)}
        onBannerColorTextChange={() => this.handleBannerColorTextChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onhandleClose={() => this.onhandleClose.bind(this)}
        onHandleCategorySelected={() => this.handleCategorySelected.bind(this)}
        onHandleChangeMaker = {() => this.handleChangeMaker.bind(this)}
        onhandleRateType={() => this.onhandleRateType.bind(this)}
        onhandleBannerStatusChange={() => this.onhandleBannerStatusChange.bind(this)}
        onhandleStatusChange={() => this.onhandleStatusChange.bind(this)}
        onhandleSelectChange={() => this.handleSelectChange.bind(this)}
        onCopyPasteCut={() => this.onCopyPasteCut.bind(this)}
        fileChange={this.fileChange}
        deleteFile={this.deleteFile}
        deleteExistingFile={this.deleteExistingFile}
      />
    );
  }
}

export default withRouter(FundEditFormBase);
