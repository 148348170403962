import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../Session/withAuthorization";
import Navigation from "../Navigation";
import { DropzoneAreaBase } from "material-ui-dropzone";
import {
  Container,
  Button,
  Divider,
  Snackbar,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AuthUserContext } from "../Session";
import "./css/requestUpload.css";
import Functions from "./functions";
import * as ROUTES from "../../constants/routes";
import * as XLSX from 'xlsx';
import { useState } from "react";
import { useEffect } from "react";

const RequestUpload = () => {
  return <Navigation content={<RequestUploadPage />} />;
};

const RequestUploadBase = (props) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false)

  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const [fundRate, setFundRate] = useState(null);
  const [prevFundRate, setPrevFundRate] = useState(null);

  const [varianceOpen, setVarianceOpen] = useState(false);
  const [varianceMessage, setVarianceMessage] = useState('');

  const isInvestment =
    props.location.pathname === ROUTES.INVESTMENT_REQUEST_UPLOAD
      ? true
      : props.name === "Investment Transactions"
        ? true
        : false;
  const email = props.firebase.auth.currentUser.email
  const type = isInvestment ? "approval" : "withdrawal";

  const functions = new Functions();

  async function getDetails() {
    const data = await props.firebase.db.collection('fundRates').orderBy("dateUploaded", "desc").limit(2).get();
    setFundRate(data.docs[0].data());
    setPrevFundRate(data.docs[1].data())
  }

  useEffect(() => {
    getDetails();
  }, [])

  const handleDropzone = (file) => {
    setUploadedFile(file[0]);
  };

  const submit = () => {
    setLoading(true);
    try {
      if (!uploadedFile) {
        throw new Error('No File Detected')
      }
      validateFile();
    } catch (err) {
      setLoading(false);
      setAlertOpen(true);
      setSeverity('error')
      setMessage(err.message);
    }
  };

  const validateFile = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];

        const sheet = workbook.Sheets[sheetName];

        const sheetData = XLSX.utils.sheet_to_json(sheet);

        let index = 1;
        for (const obj of sheetData) {
          const fund = fundRate.fundDetails.find((x) => x.fundName === obj['Fund Name']);
          const prevFund = prevFundRate.fundDetails.find((x) => x.fundName === obj['Fund Name']);

          const navps = obj['NAVPS Booking Time'] ? obj['NAVPS Booking Time'] : 0;

          if (isInvestment) {
            const sharesSys = obj['Total Amount'] / parseFloat(fund.rate);
            const sharesExcel = obj['Total Amount'] / parseFloat(obj['NAVPS Booking Time']);

            const roundingVariance = 0.05;
            const variance = Math.abs(sharesSys - sharesExcel);
            if (variance > roundingVariance) {
              throw new Error(`
              For Row: ${index}\n
              Transaction ID: ${obj['Transaction ID']}\n 
              Entered shares and expected shares do not match up with a difference of: ${variance}
              `);
            }
          } else {
            // if (obj['Status'] === 'declined') {
            //   return alert('Declined status is not available for redemptions');
            // }

            const amtInvestedSys = parseFloat(fund.rate) * obj.Shares;
            const amtInvestedExcel = parseFloat(navps) * obj.Shares;

            const roundingVariance = 0.05;
            const variance = Math.abs(amtInvestedSys - amtInvestedExcel);

            if (variance > roundingVariance) {
              throw new Error(`
              For Row: ${index}\n
              Transaction ID: ${obj['Transaction ID']}\n 
              Entered shares and expected shares do not match up with a difference of: ${variance}
              `);
            }
          }

          const navpsVariance = parseFloat(prevFund.rate) * 0.05;
          const actualVariance = Math.abs(navps - parseFloat(prevFund.rate));

          if (actualVariance > navpsVariance) {
            throw new Error(`
            For Row: ${index}\n
            Transaction ID: ${obj['Transaction ID']}\n
            Exceeds previous variance of NAVPS: ${actualVariance.toFixed(2)}`);
          }
          index += 1;
        }

        uploadFile();

      } catch (err) {
        setVarianceOpen(true);
        setVarianceMessage(err.message);
      } finally {
        setLoading(false);
      }
    };
    reader.readAsArrayBuffer(uploadedFile.file);
  }

  async function uploadFile() {
    setVarianceOpen(false);
    setLoading(true);
    try {
      const res = await functions.doUploadFile(uploadedFile, email, type)

      if (res.data.ok) {
        setUploadedFile(null);
        setLoading(false);
        setAlertOpen(true);
        setSeverity('success')
        setMessage('Successfully uploaded file');
      } else {
        throw new Error(res.data.message);
      }
    } catch (err) {
      setLoading(false);
      setAlertOpen(true);
      setSeverity('error')
      setMessage(err.message);
    }
  }

  return (
    <div className={`${!props.name && "cms-main-content"}`}>
      <div
        className={`${!props.name && "body-content"}`}
        style={{ margin: "auto" }}
      >
        <Container>
          {!props.name && (
            <>
              <h1>Upload {isInvestment ? "Booking" : "Redemption"}</h1>
              <span>
                This page is specifically for uploading approved{" "}
                {isInvestment ? " or booked" : " redeemed"} investments
              </span>
              <br /> <br />
              <div>
                <span>
                  Note: The excel file must contain ONLY{" "}
                  {isInvestment ? `'approved'` : `'redeemed'`} entries in the
                  status column.
                </span>
                <br />
                <span>Instructions:</span>
                <ol>
                  <li>Click the upload box or drag file</li>
                  <li>
                    Select {isInvestment ? "booking" : "redemption"} file. NOTE:
                    Extension should be .xlsx
                  </li>
                  <li>Click submit</li>
                </ol>
                <span>
                  The <b>Date Processed</b> column must follow the formatting{" "}
                  <b>(YYYY-MM-DD)</b>.
                </span>
              </div>
            </>
          )}
          <br />

          <DropzoneAreaBase
            acceptedFiles={[
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "xls",
            ]}
            filesLimit={1}
            showPreviewsInDropzone={true}
            onAdd={handleDropzone}
          />
          {uploadedFile && (
            <label style={{ fontSize: "14px", fontWeight: "600" }}>
              {uploadedFile.file.name}
            </label>
          )}
          <Divider style={{ marginTop: `${props.name ? "10px" : "50px"}`, marginBottom: "30px" }} />
          <div className={`${props.name ? "flex justify-center" : "flex"}`}>
            {loading ? (
              <CircularProgress />
            ) : props.name ? (

              <Button
                variant="contained"
                className={"approve-button"}
                disabled={uploadedFile === null}
                onClick={submit}
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                className={"approve-button"}
                disabled={uploadedFile === null}
                onClick={submit}
              >
                Submit
              </Button>
            )}
          </div>
        </Container>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert severity={severity}>{message}</Alert>
        </Snackbar>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={varianceOpen}
          onClose={() => setVarianceOpen(false)}
        >
          <DialogTitle>{"Inconsistency Detected"}</DialogTitle>
          <DialogContent>
            <div style={{ marginBottom: "15px" }}>
              {varianceMessage.split("\n").map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
            <b>Proceeding will upload the file</b>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setVarianceOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => uploadFile()} color="primary" autoFocus>
              Proceed Anyway
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;

const RequestUploadPage = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(RequestUploadBase);

export { RequestUploadPage };

export default RequestUpload;
