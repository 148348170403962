import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Chip,
    Drawer,
    Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FirebaseContext, withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import { withRouter } from 'react-router-dom';
import Navigation from '../Navigation';
import MUIDataTable from 'mui-datatables';
import CustomDataTable from '../CustomDataTable';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    filters: {
        display: 'flex',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    table: {
        minWidth: 650,
    },
    statusChip: {
        color: 'white',
        fontWeight: 'bold',
    },
    drawer: {
        width: 400,
        padding: theme.spacing(3),
    },
}));

const AuditLog = (props) => {
    const classes = useStyles();
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedLog, setSelectedLog] = useState(null);
    const [filters, setFilters] = useState({
        action: '',
        status: '',
        search: '',
    });

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                let query = props.firebase.db.collection('auditRecord').orderBy('timestamp', 'desc');

                if (filters.action) {
                    query = query.where('action', '==', filters.action);
                }

                if (filters.status) {
                    query = query.where('status', '==', filters.status);
                }

                const snapshot = await query.get();
                const logsData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    timestamp: doc.data().timestamp?.toDate().toLocaleString(),
                }));
                console.log(logsData)


                setLogs(logsData);
                setLoading(false);
            } catch (err) {
                console.log(err)
                setError('Failed to load audit logs');
                setLoading(false);
            }
        };

        fetchLogs();
    }, [filters.action, filters.status]);

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const filteredLogs = logs.filter(log =>
        log.documentId?.toLowerCase().includes(filters.search.toLowerCase()) ||
        log.userEmail?.toLowerCase().includes(filters.search.toLowerCase())
    );

    const columns = [
        {
            name: 'timestamp',
            label: 'Timestamp',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'action',
            label: 'Action',
            options: {
                filter: true,
                filterOptions: {
                    names: ['create', 'update', 'delete', 'login'],
                },
                customBodyRender: (value) => (
                    <span style={{ textTransform: 'capitalize' }}>{value}</span>
                )
            }
        },
        {
            name: 'userEmail',
            label: 'By',
            options: {
                filterType: 'textField',
            }
        },
        {
            name: 'changes',
            label: 'Affected Item',
            options: {
                filterType: 'textField',
                customBodyRender: (value) => {
                    const val = value.newValue ?? value.oldValue;

                    return (
                        <div style={{ maxWidth: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {val}
                        </div>
                    )
                }
            }
        },
    ];

    const options = {
        filterType: 'dropdown',
        responsive: 'vertical',
        selectableRows: 'none',
        print: false,
        download: false,
        viewColumns: false,
        onRowClick: (rowData, { dataIndex }) => {
            setSelectedLog(logs[dataIndex]);
        },
        customSearch: (searchQuery, currentRow, columns) => {
            const searchTerm = searchQuery.toLowerCase();
            return (
                currentRow[1].toLowerCase().includes(searchTerm) || // User ID
                currentRow[4].toLowerCase().includes(searchTerm)    // Document ID
            );
        },
    };

    return (
        <div className="cms-main-content">
            <div className="body-content">
                <CustomDataTable
                    title="Audit Logs"
                    data={logs}
                    columns={columns}
                    options={options}
                />

                <Drawer
                    anchor="right"
                    open={!!selectedLog}
                    onClose={() => setSelectedLog(null)}
                >
                    <div className={classes.drawer}>
                        {selectedLog && (
                            <>
                                <Typography variant="h4" gutterBottom>
                                    Audit Log Details
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Timestamp:</strong> {selectedLog.timestamp}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    Changes:
                                </Typography>
                                <pre style={{
                                    backgroundColor: '#f5f5f5',
                                    padding: 16,
                                    borderRadius: 4,
                                    maxHeight: 400,
                                    overflow: 'auto'
                                }}>
                                    {JSON.stringify(selectedLog.changes, null, 2)}
                                </pre>

                                <Typography variant="subtitle1" gutterBottom>
                                    Metadata:
                                </Typography>
                                <pre style={{
                                    backgroundColor: '#f5f5f5',
                                    padding: 16,
                                    borderRadius: 4
                                }}>
                                    {JSON.stringify(selectedLog.metadata, null, 2)}
                                </pre>
                            </>
                        )}
                    </div>
                </Drawer>
            </div>
        </div>
    );
};

const condition = (authUser) => !!authUser;

const AuditLogPage = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(() =>
    <FirebaseContext.Consumer>
        {(firebase) => (
            <Navigation
                content={
                    <AuditLog
                        firebase={firebase}
                    />
                }
            />
        )}

    </FirebaseContext.Consumer>
)

export default AuditLogPage;