import React, { useMemo } from "react";

import {
  Breadcrumbs,
  Link,
  Divider,
  Tab,
  AccordionSummary,
  AccordionDetails,
  Button,
  Snackbar,
  Slide,
  FormControl,
  Select,
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  Paper,
  TextField,
  Tooltip,
  Grid,
  Chip,
  DialogTitle,
  DialogActions,
  LinearProgress,
  SvgIcon,
  FormControlLabel,
  Switch,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
} from "@material-ui/core";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import {
  AssignmentIndRounded,
  AttachFileRounded,
  ExpandMore,
  WarningRounded,
} from "@material-ui/icons";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAlert from "@material-ui/lab/Alert";

import { withStyles } from "@material-ui/core/styles";

import "./css/clientProfile.css";

import { DropzoneAreaBase } from "material-ui-dropzone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "../../../index.css";

import conservativeLogo from "../../../images/conservative.png";
import moderateLogo from "../../../images/moderate.png";
import aggressiveLogo from "../../../images/aggressive.png";
import downloadLogo from "../../../images/downloadLogo.png";

import tier2 from "../../../images/Tier2.png";
import tier3 from "../../../images/Tier3.png";

var imgSrc = "";
const DATE_OPTIONS = { year: "numeric", month: "long", day: "numeric" };
const DATE_OPTIONS_TIME = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "PHP",
  minimumFractionDigits: 2,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Accordion = withStyles({
  root: {
    border: "none !important",
    boxShadow: "none !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const ClientAttachments = (props, tier) => {
  const labelID = (
    <>
      <span className=" font-semibold">Is this a primary ID?</span> Note: For
      primary IDs, no need to submit additional IDs in Full KYC
    </>
  );
  return (
    <>
      <Dialog
        open={props.props.state.editDialogVisible}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle>{"Edit Additional Documents"}</DialogTitle>
        <DialogContent>
          {props.props.state.editDocumentsDisplay === undefined ? (
            ""
          ) : (
            <>
              <h4>Existing files</h4>
              {props.props.state.editDocumentsDisplay.map((data, index) => {
                let fileName = data.split("/")[data.split("/").length - 1];
                return (
                  <Chip
                    id={`chip ${index}`}
                    label={fileName}
                    onDelete={props.props.removeEditItem(index)}
                  />
                );
              })}
            </>
          )}

          <br />
          <br />
          <Divider />
          <br />
          <h4>Additional files</h4>
          <DropzoneAreaBase
            filesLimit={1}
            onAdd={props.props.handleDropzone()}
          />
          <br />
          {props.props.state.additionalDocuments.map((data, index) => {
            return (
              <Chip
                id={`chip ${index}`}
                ariaLabel={`chip ${index}`}
                label={data.file.name}
                onDelete={props.props.handleDropzoneDelete(index)}
              />
            );
          })}
          {props.props.state.editLoading ? <LinearProgress /> : ""}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.props.CloseEditDialog(
              props.props.state.additionalDocumentsDisplay
            )}
            color="secondary"
            disabled={props.props.state.editLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={props.props.acceptEditChanges()}
            color="primary"
            disabled={props.props.state.editLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <label className={"attachmentLabel"}>All Valid ID's</label>
        </Grid>
        {props.props.state.idImages.length > 0 &&
        props.props.state.idImages.map((item) => {
          return (
            <Grid item xs={4}>
              <div
                data-type={item.image}
                className={"attachmentDiv"}
                onClick={props.props.onShowImage(item.url, true)}
              >
                <img
                  src={downloadLogo}
                  className={"attachmentIcon"}
                  alt="download"
                />
                <div style={{ textAlign: "left" }}>
                  <label className={"itemTitle"}>{item.name}</label>
                  <br />
                  <label
                    className={"itemDescription"}
                  >{`${item.size} MB`}</label>
                </div>
              </div>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={props.props.state.ids.isPrimaryId}
                onChange={(e) => props.props.setValidId(e.target.checked)}
                name="checkedB"
                color="primary"
                disabled={tier === 3}
              />
            }
            label={labelID}
          />
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <label className={"attachmentLabel"}>Additional Documents</label>
        </Grid>
        <Grid item xs={1}>
          <Button
            color="primary"
            fullWidth
            onClick={props.props.OpenEditDialog()}
          >
            Edit
          </Button>
        </Grid>
        {props.props.state.additionalDocumentsDisplay !== undefined &&
          props.props.state.additionalDocumentsDisplay.length > 0 ? (
          <>
            {props.props.state.additionalDocumentsDisplay.map((item) => {
              let fileName = item.split("/")[item.split("/").length - 1];
              return (
                <Grid item xs={4}>
                  <div
                    onClick={props.props.GetDownloadFile(item)}
                    className={"attachmentDiv"}
                  >
                    <img
                      src={downloadLogo}
                      className={"attachmentIcon"}
                      alt="download"
                    />
                    <div style={{ textAlign: "left" }}>
                      <label className={"itemTitle"}>{fileName}</label>
                      <br />
                    </div>
                  </div>
                </Grid>
              );
            })}
          </>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

const PendingTransactions = (props) => {
  var pendingTransaction = props.props.state.investmentDetails.filter(
    (item) => item.status === "pending"
  );

  return (
    <>
      <div
        className={
          pendingTransaction.length > 4 ? "pendingApprovedContainer" : ""
        }
      >
        {pendingTransaction.length > 0 ? (
          pendingTransaction.map((item, index) => (
            <>
              <div
                key={item.transId}
                data-trans-key={item.transId}
                data-trans-type={"pending"}
                onClick={props.props.onItemTransactionChange()}
                className={
                  props.props.state.transSelectedId === item.transId
                    ? "tabItemsContainerActive"
                    : "tabItemsContainer"
                }
              >
                <div key={item.fundDetails.fundName}>
                  <label className={"itemTitle"}>
                    {item.fundDetails.fundName !== undefined
                      ? item.fundDetails.fundName
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction ID: &nbsp;
                    {item.transId !== undefined ? item.transId : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Date and Time: &nbsp;
                    {item.datePlaced !== undefined
                      ? new Date(item.datePlaced.toDate()).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS_TIME
                      )
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction Amount: &nbsp;
                    {item.amount !== undefined
                      ? formatter.format(item.amount)
                      : "N/A"}
                  </label>
                </div>
              </div>
              <br />
            </>
          ))
        ) : (
          <label className={"itemDescription"}>No pending investments.</label>
        )}
      </div>
    </>
  );
};

const ProcessingTransactions = (props) => {
  var processingTransaction = props.props.state.investmentDetails.filter(
    (item) => item.status === "processing"
  );

  return (
    <>
      <div
        className={
          processingTransaction.length > 4 ? "pendingApprovedContainer" : ""
        }
      >
        {processingTransaction.length > 0 ? (
          processingTransaction.map((item, index) => (
            <>
              <div
                key={item.transId}
                data-trans-key={item.transId}
                data-trans-type={"processing"}
                onClick={props.props.onItemTransactionChange()}
                className={
                  props.props.state.transSelectedId === item.transId
                    ? "tabItemsContainerActive"
                    : "tabItemsContainer"
                }
              >
                <div key={item.fundDetails.fundName}>
                  <label className={"itemTitle"}>
                    {item.fundDetails.fundName !== undefined
                      ? item.fundDetails.fundName
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction ID: &nbsp;
                    {item.transId !== undefined ? item.transId : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Date and Time: &nbsp;
                    {item.datePlaced !== undefined
                      ? new Date(item.datePlaced.toDate()).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS_TIME
                      )
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction Amount: &nbsp;
                    {item.amount !== undefined
                      ? formatter.format(item.amount)
                      : "N/A"}
                  </label>
                </div>
              </div>
              <br />
            </>
          ))
        ) : (
          <label className={"itemDescription"}>
            No processing investments.
          </label>
        )}
      </div>
    </>
  );
};

const ApproveTransactions = (props) => {
  var approvedTransaction = props.props.state.investmentDetails.filter(
    (item) => item.status === "approved"
  );

  return (
    <>
      <div
        className={
          approvedTransaction.length > 4 ? "pendingApprovedContainer" : ""
        }
      >
        {approvedTransaction.length > 0 ? (
          approvedTransaction.map((item, index) => (
            <>
              <div
                key={item.transId}
                data-trans-key={item.transId}
                data-trans-type={"approved"}
                onClick={props.props.onItemTransactionChange()}
                className={
                  props.props.state.transSelectedId === item.transId
                    ? "tabItemsContainerActive"
                    : "tabItemsContainer"
                }
              >
                <div key={item.fundDetails.fundName}>
                  <label className={"itemTitle"}>
                    {item.fundDetails.fundName !== undefined
                      ? item.fundDetails.fundName
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction ID: &nbsp;
                    {item.transId !== undefined ? item.transId : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Date and Time: &nbsp;
                    {item.datePlaced !== undefined
                      ? new Date(item.datePlaced.toDate()).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS_TIME
                      )
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction Amount: &nbsp;
                    {item.amount !== undefined
                      ? formatter.format(item.amount)
                      : "N/A"}
                  </label>
                </div>
              </div>
              <br />
            </>
          ))
        ) : (
          <label className={"itemDescription"}>No approved investments.</label>
        )}
      </div>
    </>
  );
};

const DeclinedTransactions = (props) => {
  const declinedTransaction = props.props.state.investmentDetails.filter(
    (item) => item.status === "declined"
  );

  return (
    <>
      <div
        className={
          declinedTransaction.length > 4 ? "pendingApprovedContainer" : ""
        }
      >
        {declinedTransaction.length > 0 ? (
          declinedTransaction.map((item, index) => (
            <>
              <div
                key={item.transId}
                data-trans-key={item.transId}
                data-trans-type={"declined"}
                onClick={props.props.onItemTransactionChange()}
                className={
                  props.props.state.transSelectedId === item.transId
                    ? "tabItemsContainerActive"
                    : "tabItemsContainer"
                }
              >
                <div key={item.fundDetails.fundName}>
                  <label className={"itemTitle"}>
                    {item.fundDetails.fundName !== undefined
                      ? item.fundDetails.fundName
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction ID: &nbsp;
                    {item.transId !== undefined ? item.transId : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Date and Time: &nbsp;
                    {item.datePlaced !== undefined
                      ? new Date(item.datePlaced.toDate()).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS_TIME
                      )
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction Amount: &nbsp;
                    {item.amount !== undefined
                      ? formatter.format(item.amount)
                      : "N/A"}
                  </label>
                </div>
              </div>
              <br />
            </>
          ))
        ) : (
          <label className={"itemDescription"}>No declined investments.</label>
        )}
      </div>
    </>
  );
};

const PendingWithdrawals = (props) => {
  var pendingWithdrawals = props.props.state.investmentDetails.filter(
    (item) => item.status === "pendingwithdrawal"
  );

  return (
    <>
      <div
        className={
          pendingWithdrawals.length > 4 ? "pendingApprovedContainer" : ""
        }
      >
        {pendingWithdrawals.length > 0 ? (
          pendingWithdrawals.map((item, index) => (
            <>
              <div
                key={item.transId}
                data-trans-key={item.transId}
                data-trans-type={"pendingwithdrawal"}
                onClick={props.props.onItemTransactionChange()}
                className={
                  props.props.state.transSelectedId === item.transId
                    ? "tabItemsContainerActive"
                    : "tabItemsContainer"
                }
              >
                <div key={item.fundDetails.fundName}>
                  <label className={"itemTitle"}>
                    {item.fundDetails.fundName !== undefined
                      ? item.fundDetails.fundName
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction ID: &nbsp;
                    {item.transId !== undefined ? item.transId : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Date and Time: &nbsp;
                    {item.datePlaced !== undefined
                      ? new Date(item.datePlaced.toDate()).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS_TIME
                      )
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction Amount: &nbsp;
                    {item.amount !== undefined
                      ? formatter.format(item.amount)
                      : "N/A"}
                  </label>
                </div>
              </div>
              <br />
            </>
          ))
        ) : (
          <label className={"itemDescription"}>No pending redemptions.</label>
        )}
      </div>
    </>
  );
};

const ProcessingWithdrawals = (props) => {
  var processingWithdrawals = props.props.state.investmentDetails.filter(
    (item) => item.status === "processingredemption"
  );

  return (
    <>
      <div
        className={
          processingWithdrawals.length > 4 ? "pendingApprovedContainer" : ""
        }
      >
        {processingWithdrawals.length > 0 ? (
          processingWithdrawals.map((item, index) => (
            <>
              <div
                key={item.transId}
                data-trans-key={item.transId}
                data-trans-type={"processingwithdrawal"}
                onClick={props.props.onItemTransactionChange()}
                className={
                  props.props.state.transSelectedId === item.transId
                    ? "tabItemsContainerActive"
                    : "tabItemsContainer"
                }
              >
                <div key={item.fundDetails.fundName}>
                  <label className={"itemTitle"}>
                    {item.fundDetails.fundName !== undefined
                      ? item.fundDetails.fundName
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction ID: &nbsp;
                    {item.transId !== undefined ? item.transId : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Date and Time: &nbsp;
                    {item.datePlaced !== undefined
                      ? new Date(item.datePlaced.toDate()).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS_TIME
                      )
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction Amount: &nbsp;
                    {item.amount !== undefined
                      ? formatter.format(item.amount)
                      : "N/A"}
                  </label>
                </div>
              </div>
              <br />
            </>
          ))
        ) : (
          <label className={"itemDescription"}>
            No processing redemptions.
          </label>
        )}
      </div>
    </>
  );
};

const Withdrawns = (props) => {
  var withdrawns = props.props.state.investmentDetails.filter(
    (item) => item.status === "redeemed"
  );

  return (
    <>
      <div className={withdrawns.length > 4 ? "pendingApprovedContainer" : ""}>
        {withdrawns.length > 0 ? (
          withdrawns.map((item, index) => (
            <>
              <div
                key={item.transId}
                data-trans-key={item.transId}
                data-trans-type={"withdrawn"}
                onClick={props.props.onItemTransactionChange()}
                className={
                  props.props.state.transSelectedId === item.transId
                    ? "tabItemsContainerActive"
                    : "tabItemsContainer"
                }
              >
                <div key={item.fundDetails.fundName}>
                  <label className={"itemTitle"}>
                    {item.fundDetails.fundName !== undefined
                      ? item.fundDetails.fundName
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction ID: &nbsp;
                    {item.transId !== undefined ? item.transId : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Date and Time: &nbsp;
                    {item.datePlaced !== undefined
                      ? new Date(item.datePlaced.toDate()).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS_TIME
                      )
                      : "N/A"}
                  </label>
                  <br />
                  <label className={"itemDescription"}>
                    {" "}
                    Transaction Amount: &nbsp;
                    {item.amount !== undefined
                      ? formatter.format(item.amount)
                      : "N/A"}
                  </label>
                </div>
              </div>
              <br />
            </>
          ))
        ) : (
          <label className={"itemDescription"}>No redeemed transactions.</label>
        )}
      </div>
    </>
  );
};

const ClientTransactions = (props) => {
  var transId = props.props.state.transSelectedId;
  var statusArray = ["pending", "processing", "approved", "matured"];

  var details = props.props.state.investmentDetails.find(
    (item) => item.transId === transId
  );

  return details ? (
    <>
      <div className={"content2Title"}>
        <div>
          <label className={"content2ItemTitle"}>
            {details.fundDetails.fundName
              ? details.fundDetails.fundName
              : "N/A"}
          </label>
          <br />
          <label className={"content2ItemDescription"}>
            {`Transaction ID: ${details.transId}`}
          </label>
        </div>
      </div>

      <table className={"accordionTable"}>
        <tbody>
          <tr>
            <td>Fund Name</td>
            <td>
              {details.fundDetails.fundName
                ? details.fundDetails.fundName
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>Account Name</td>
            <td>
              {details.clientDetails
                ? `${details.clientDetails.userFirstName} ${details.clientDetails.userLastName}`
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>Account ID</td>
            <td style={{ textTransform: "none" }}>{details.accountNumber}</td>
          </tr>
          <tr>
            <td>Inputed Amount</td>
            <td>
              {details.amount !== "N/A"
                ? formatter.format(details.amount)
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>Date and Time</td>
            <td>
              {details.datePlaced !== "N/A"
                ? new Date(details.datePlaced.toDate()).toLocaleDateString(
                  "en-US",
                  DATE_OPTIONS_TIME
                )
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td style={{ textTransform: "none" }}>
              {details.clientDetails.userEmail !== undefined &&
                details.clientDetails.userEmail !== ""
                ? details.clientDetails.userEmail
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>Contact Number</td>
            <td>{props.props.state.mobileNumber}</td>
          </tr>
          <tr>
            <td>Company</td>
            <td>
              {details.fundCompDetails.fundCompanyName
                ? details.fundCompDetails.fundCompanyName
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>Folio Number</td>
            <td>{details.folioNumber}</td>
          </tr>
          <tr>
            <td>Shares</td>
            <td>{details.shares}</td>
          </tr>
          <tr>
            <td>Rate at Booking Time</td>
            <td>{details.navps}</td>
          </tr>
          <tr>
            <td>Fee</td>
            <td>{details.fee}</td>
          </tr>
          <tr>
            <td>Current Rate</td>
            <td>{details.currentRate}</td>
          </tr>
          <tr>
            <td>Total Value</td>
            <td>{details.total_value}</td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />

      {statusArray.includes(details.status) && details.depositSlip && (
        <>
          <div>
            <label className={"attachmentLabel"}>Attachments</label>
          </div>

          <div
            data-type={6}
            className={"attachmentDiv"}
            onClick={props.props.onShowImage(details.depositSlip, false)}
          >
            <img
              src={downloadLogo}
              className={"attachmentIcon"}
              alt="download"
            />
            <div style={{ textAlign: "left" }}>
              <label className={"itemTitle"}>Deposit Slip</label>
              <br />
              <label
                className={"itemDescription"}
              >{`${details.depositSlipSize} MB`}</label>
            </div>
            {props.props.state.depositSlipLoading ? (
              <div style={{ marginLeft: "20px" }}>
                <CircularProgress />
              </div>
            ) : (
              ""
            )}
          </div>

          {details.depositSlipSecondary !== "N/A" ? (
            <div
              data-type={6}
              className={"attachmentDiv"}
              onClick={props.props.onShowImage(
                details.depositSlipSecondary,
                false
              )}
            >
              <img
                src={downloadLogo}
                className={"attachmentIcon"}
                alt="download"
              />
              <div style={{ textAlign: "left" }}>
                <label className={"itemTitle"}>Deposit Slip - Secondary</label>
                <br />
                <label
                  className={"itemDescription"}
                >{`${details.depositSlipSize} MB`}</label>
              </div>
              {props.props.state.depositSlipLoading ? (
                <div style={{ marginLeft: "20px" }}>
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  ) : (
    <center>
      <label className={"noInvestmentContent"}>No transactions.</label>
    </center>
  );
};

const Risk = (risk) => {
  let icon;
  let label;
  if (risk === undefined) label = "n/a";
  else label = `${risk[0].toUpperCase()}${risk.slice(1).toLowerCase()}`;

  let blurb = "";
  switch (risk) {
    case `conservative`:
      icon = conservativeLogo;
      blurb =
        "You are an investor with a low appetite for risk and you’re amenable to modest returns.";
      break;

    case `moderate`:
      icon = moderateLogo;
      blurb =
        "You are ready to invest and are currently looking at a long-term investing horizon.";

      break;

    case `aggressive`:
      icon = aggressiveLogo;
      blurb =
        "You have a high appetite for risk and willing to invest for the long-term.";
      break;

    default:
      break;
  }

  return (
    <>
      <div className={"riskIconBackgroundCon"}>
        <img className={"iconSize"} src={icon} alt={label} />
      </div>
      <div>
        <label className={"itemTitle"}>{label}</label>
        <br />
        <span>{blurb}</span>
      </div>
    </>
  );
};

const ClientProfileForm = (props) => {
  const stringToWhiteSpaceConverter = (text) => {
    let returnText = text.replace(/\/n /gi, `\n`);
    return returnText;
  };

  const {
    tabValue,
    itemSelected,
    panelsExpand,
    email,
    investmentManager,
    name,
    toggleDialog,
    toggleAlert,
    alertType,
    alertMessage,
    isLoading,
    managerList,
    clientDetails,
    dialogType,
    imageType,
    toggleShowImage,
    idPhotoFrontSrc,
    idPhotoBackSrc,
    selfieSrc,
    proofOfAddressSrc,
    PEPDocumentSrc,
    totalInvestmentAmount,
    depositSlipSrc,
    signatureSrc,
    validated,
    comments,
    commentToAdd,
    stats,
  } = props.state;

  var hasInvestmentManager = investmentManager.investmentManagerId !== "";

  let tier = !stats.tier ? 1 : stats.tier;

  const isLegacy =
    stats.tier === undefined ||
    stats.tier === null ||
    props.state.FATCA.length > 0;

  if (props.state.validated === "pending") {
    if (tier === 1) {
      tier = 2;
    } else {
      tier = 3;
    }
  }

  switch (imageType) {
    case 1:
      imgSrc = idPhotoFrontSrc;
      break;

    case 2:
      imgSrc = idPhotoBackSrc;
      break;

    case 3:
      imgSrc = selfieSrc;
      break;

    case 4:
      imgSrc = proofOfAddressSrc;
      break;

    case 5:
      imgSrc = PEPDocumentSrc;
      break;

    case 6:
      imgSrc = depositSlipSrc;
      break;

    case 7:
      imgSrc = signatureSrc;
      break;

    default:
      imgSrc = "";
      break;
  }

  let incomeSelection = "";
  switch (props.state.work.annualIncomeAmount) {
    case 1:
      incomeSelection = "PHP 50,000 or less";
      break;

    case 2:
      incomeSelection = "PHP 50,001 to PHP 100,000,";
      break;

    case 3:
      incomeSelection = "PHP 100,001 to PHP 500,000";
      break;

    case 4:
      incomeSelection = "PHP 500,001 to PHP 1,000,000";
      break;

    case 5:
      incomeSelection = "More than PHP 1,000,000";
      break;

    default:
      incomeSelection = "N/A";
  }

  function DeleteItem(item) {
    //console.log(item)
    props.handleDropzoneDelete();
  }

  const tierComponent = useMemo(() => {
    const isPending = props.state.validated === "pending";
    let displayTier = tier;

    if (isPending) displayTier--;

    if (props.state.tierUpgradeStatus === "pending") displayTier = 2;

    switch (displayTier) {
      case 2:
        return (
          <div style={{ marginRight: "19px" }}>
            <Tooltip title="Tier 2" placement="bottom">
              <img src={tier2} alt="Tier 2" />
            </Tooltip>
          </div>
        );

      case 3:
        return (
          <div style={{ marginRight: "19px" }}>
            <Tooltip title="Tier 3" placement="bottom">
              <img src={tier3} alt="Tier 3" />
            </Tooltip>
          </div>
        );
      default:
        return (
          <div className={"content2IconBackground"}>
            <Tooltip title="Tier 1" placement="bottom">
              <AssignmentIndRounded className={"content2IconSize"} />
            </Tooltip>
          </div>
        );
    }
  }, [tier]);

  return (
    <div className={"root"}>
      <div className={"containerLeft"}>
        <Breadcrumbs
          className={"breadcrumbsSize"}
          style={{ cursor: "default", textDecoration: "none" }}
        >
          <Link color="inherit">Clients</Link>
          <Link color="textPrimary">
            {name.firstName !== undefined &&
              name.middleName !== undefined &&
              name.lastName !== undefined &&
              name.firstName !== "" &&
              name.middleName !== "" &&
              name.lastName !== ""
              ? `${name.firstName} ${name.middleName} ${name.lastName}`
              : "N/A"}
          </Link>
        </Breadcrumbs>

        <br />
        <label className={"clientNameLabel"}>
          {name.firstName !== undefined &&
            name.middleName !== undefined &&
            name.lastName !== undefined &&
            name.firstName !== "" &&
            name.middleName !== "" &&
            name.lastName !== ""
            ? `${name.firstName} ${name.middleName} ${name.lastName}`
            : "N/A"}
        </label>
        <br />
        <label className={"clientEmailLabel"}>
          {email !== undefined && email !== "" ? email : "N/A"}
        </label>

        <table className={"detailsTable"}>
          <tbody>
            <tr>
              <td>Account ID:</td>
              <td>
                {clientDetails.accountNumber !== ""
                  ? clientDetails.accountNumber
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td>Risk Profile:</td>
              <td style={{ textTransform: "capitalize" }}>
                {clientDetails.riskProfile !== undefined
                  ? clientDetails.riskProfile
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td>Financial Advisor:</td>
              <td>
                {investmentManager.investmentManagerName !== undefined &&
                  investmentManager.investmentManagerName !== ""
                  ? investmentManager.investmentManagerName
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td>Referrer:</td>
              <td>
                {clientDetails.referrerId !== undefined &&
                  clientDetails.referrerId !== ""
                  ? clientDetails.referrerId
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td>Application Status:</td>
              <td>
                {validated.toString().toLowerCase() === "verified"
                  ? "Approved"
                  : "To be reviewed"}
              </td>
            </tr>
            <tr>
              <td>Total Investment Amount</td>
              <td>
                {totalInvestmentAmount > 1
                  ? formatter.format(totalInvestmentAmount)
                  : "PHP 0.00"}
              </td>
            </tr>
          </tbody>
        </table>
        <Button
          data-type="assign"
          onClick={props.handleDialogType()}
          className={
            investmentManager.length <= 0 ||
              investmentManager.investmentManagerId === ""
              ? "assignManagerButton"
              : "assignManagerButton2"
          }
        >
          {investmentManager.length <= 0 ||
            investmentManager.investmentManagerId === ""
            ? "Assign a Financial Advisor"
            : "Change Financial Advisor"}
        </Button>
        {(investmentManager.length <= 0 ||
          investmentManager.investmentManagerId === "") &&
          clientDetails.preferredFA !== "" ? (
          <Paper elevation={3} style={{ padding: "10px" }}>
            <p>
              The client has requested this person to be their Financial
              Advisor:
            </p>
            <b>{clientDetails.preferredFA}</b>
          </Paper>
        ) : (
          ""
        )}

        <Divider />

        <TabContext value={tabValue}>
          <TabList
            onChange={props.onTabChange()}
            classes={{
              indicator: "tabBottomColor",
            }}
          >
            <Tab
              label="Applications"
              value={1}
              className={tabValue === 1 ? "tabTextActive" : "tabText"}
            />
            <Tab
              label="Transactions"
              value={2}
              className={tabValue === 2 ? "tabTextActive" : "tabText"}
            />
          </TabList>
          <TabPanel value={1} style={{ padding: 0 }}>
            <br />
            <div
              onClick={props.onItemChange1()}
              className={
                itemSelected === 1
                  ? "tabItemsContainerActive"
                  : "tabItemsContainer"
              }
            >
              <div className={"iconBackground"}>
                <AssignmentIndRounded className={"iconSize"} />
              </div>
              <div>
                <label className={"itemTitle"}>
                  Know Your Customer Application
                </label>
                <br />
                <label className={"itemDescription"}>
                  {clientDetails.isInvestor ? "Approved" : "To be reviewed"}
                </label>
              </div>
            </div>
            <br />
            <div
              onClick={props.onItemChange2()}
              className={
                itemSelected === 2
                  ? "tabItemsContainerActive"
                  : "tabItemsContainer"
              }
            >
              <div className={"iconBackground"}>
                <AttachFileRounded className={"iconSize"} />
              </div>
              <div>
                <label className={"itemTitle"}>Attachments</label>
                <br />
                <label className={"itemDescription"}>Attached files</label>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={2} style={{ padding: 0 }}>
            <br />
            {[
              {
                label: "Processing Investment",
                content: <ProcessingTransactions props={props} />,
              },
              {
                label: "Approved Investment",
                content: <ApproveTransactions props={props} />,
              },
              {
                label: "Declined Investment",
                content: <DeclinedTransactions props={props} />,
              },
              {
                label: "Redemptions for Processing",
                content: <ProcessingWithdrawals props={props} />,
              },
              {
                label: "Processed Redemptions",
                content: <Withdrawns props={props} />,
              },
            ].map((item) => (
              <Accordion style={{ width: "100%" }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-short"
                >
                  <label
                    className={"accordionTableTitle"}
                    style={{ cursor: "pointer" }}
                  >
                    {item.label}
                  </label>
                </AccordionSummary>
                <AccordionDetails>{item.content}</AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        </TabContext>
      </div>
      <div className={"containerRight"}>
        {tabValue === 1 ? (
          itemSelected === 1 ? (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "8px" }}>{tierComponent}</div>
                <div>
                  <label className={"content2ItemTitle"}>
                    Know Your Customer Application
                  </label>
                  <br />
                  <label className={"content2ItemDescription"}>
                    KYC application provided by the investor
                  </label>
                </div>
              </div>
              {validated.toString().toLowerCase() === "verified" && (
                <div className="validatedLabel">Application Validated</div>
              )}
              <Button
                className={"btnNextPrev"}
                onClick={props.onToggleAllFolders(panelsExpand)}
              >
                {panelsExpand ? "Collapse All Folders" : "Expand All Folders"}
              </Button>

              <div>
                <Accordion
                  style={{ width: "100%" }}
                  expanded={props.state.panels.panelClientInfo}
                  onChange={props.onTogglePanel("panelClientInfo")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panelClientInfo-content"
                    id="panelClientInfo"
                  >
                    <label className={"accordionTitle"}>
                      Client Information
                    </label>
                  </AccordionSummary>
                  <AccordionDetails>
                    <table className={"accordionTable"}>
                      <tbody>
                        <tr>
                          <td>Last Name</td>
                          <td>
                            {props.state.name !== undefined &&
                              props.state.name !== ""
                              ? props.state.name.lastName
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>First Name</td>
                          <td>
                            {props.state.name !== undefined &&
                              props.state.name !== ""
                              ? props.state.name.firstName
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Middle Name</td>
                          <td>
                            {props.state.name !== undefined &&
                              props.state.name !== ""
                              ? props.state.name.middleName
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Suffix</td>
                          <td>
                            {props.state.name !== undefined &&
                              props.state.name !== ""
                              ? props.state.name.suffix === undefined
                                ? "-"
                                : props.state.name.suffix
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td style={{ textTransform: "none" }}>
                            {props.state.email !== undefined &&
                              props.state.email !== ""
                              ? props.state.email
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Mobile number</td>
                          <td>
                            {
                              <PhoneInput
                                country={"ph"}
                                masks={{
                                  ph: "(...) ... ....",
                                }}
                                inputStyle={{
                                  color: "black",
                                  padding: "8px 0px 8px 30px",
                                }}
                                containerClass="mobile-display"
                                disabled={true}
                                value={props.state.mobileNumber}
                                specialLabel=""
                              />
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>
                            {props.state.gender !== undefined &&
                              props.state.gender !== ""
                              ? props.state.gender
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Civil Status</td>
                          <td>
                            {props.state.civil.civilStatus !== undefined &&
                              props.state.civil.civilStatus !== ""
                              ? props.state.civil.civilStatus
                              : "N/A"}
                          </td>
                        </tr>
                        {props.state.civil.civilStatus === "married" && (
                          <tr>
                            <td>Spouse</td>
                            <td>{props.state.civil.spouseName}</td>
                          </tr>
                        )}
                        <tr>
                          <td>Date of Birth</td>
                          <td>
                            {new Date(
                              props.state.dateOfBirth
                            ).toLocaleDateString("en-US", DATE_OPTIONS)}
                          </td>
                        </tr>
                        <tr>
                          <td>Place of Birth</td>
                          <td>{props.state.placeOfBirth}</td>
                        </tr>
                        <tr>
                          <td>City of Birth</td>
                          <td>
                            {props.state.cityOfBirth !== undefined &&
                              props.state.cityOfBirth !== ""
                              ? props.state.cityOfBirth
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Nationality</td>
                          <td>{props.state.nationality}</td>
                        </tr>
                        {(stats.tier === undefined ||
                          tier === 2 ||
                          tier === 3 ||
                          isLegacy) && (
                            <>
                              <tr>
                                <td>SSS/GSIS</td>
                                <td>
                                  {props.state.ids.SSS !== undefined &&
                                    props.state.ids.SSS !== ""
                                    ? props.state.ids.SSS
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>TIN</td>
                                <td>
                                  {props.state.ids.TIN !== undefined &&
                                    props.state.ids.TIN !== ""
                                    ? props.state.ids.TIN
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Requested Financial Advisor</td>
                                <td>
                                  {clientDetails.preferredFA !== undefined &&
                                    clientDetails.preferredFA !== ""
                                    ? clientDetails.preferredFA
                                    : "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={{ width: "100%" }}
                  expanded={props.state.panels.panelAddress}
                  onChange={props.onTogglePanel("panelAddress")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panelAddress-content"
                    id="panelAddress"
                  >
                    <label className={"accordionTitle"}>Address Details</label>
                  </AccordionSummary>
                  <AccordionDetails>
                    <table className={"accordionTable"}>
                      <tbody>
                        <tr>
                          <td colSpan="2" className={"accordionTableTitle"}>
                            Current Address
                          </td>
                        </tr>
                        <tr>
                          <td>Street, Subdivision, Barangay</td>
                          <td>
                            {props.state.currentAdd.barangay !== undefined &&
                              props.state.currentAdd.barangay !== ""
                              ? props.state.currentAdd.barangay
                              : props.state.currentAdd.street !== undefined &&
                                props.state.currentAdd.street !== ""
                                ? props.state.currentAdd.street
                                : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>City/Municipality</td>
                          <td>
                            {props.state.currentAdd.city !== undefined &&
                              props.state.currentAdd.city !== ""
                              ? props.state.currentAdd.city
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Province</td>
                          <td>
                            {props.state.currentAdd.province !== undefined &&
                              props.state.currentAdd.province !== ""
                              ? props.state.currentAdd.province
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Country</td>
                          <td>
                            {props.state.currentAdd.country !== undefined &&
                              props.state.currentAdd.country !== ""
                              ? props.state.currentAdd.country
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Zip Code</td>
                          <td>
                            {props.state.currentAdd.zip !== undefined &&
                              props.state.currentAdd.zip !== ""
                              ? props.state.currentAdd.zip
                              : "N/A"}
                          </td>
                        </tr>
                        {(stats.tier === undefined ||
                          tier === 3 ||
                          isLegacy) && (
                            <>
                              <tr>
                                <td colSpan="2" className={"accordionTableTitle"}>
                                  Permanent Address/Mailing Address
                                </td>
                              </tr>
                              <tr>
                                <td>Street, Subdivision, Barangay</td>
                                <td>
                                  {props.state.permanentAdd.barangay !==
                                    undefined &&
                                    props.state.permanentAdd.barangay !== ""
                                    ? props.state.permanentAdd.barangay
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>City/Municipality</td>
                                <td>
                                  {props.state.permanentAdd.city !== undefined &&
                                    props.state.permanentAdd.city !== ""
                                    ? props.state.permanentAdd.city
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Province</td>
                                <td>
                                  {props.state.permanentAdd.province !==
                                    undefined &&
                                    props.state.permanentAdd.province !== ""
                                    ? props.state.permanentAdd.province
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Country</td>
                                <td>
                                  {props.state.permanentAdd.country !==
                                    undefined &&
                                    props.state.permanentAdd.country !== ""
                                    ? props.state.permanentAdd.country
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Zip Code</td>
                                <td>
                                  {props.state.permanentAdd.zip !== undefined &&
                                    props.state.permanentAdd.zip !== ""
                                    ? props.state.permanentAdd.zip
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2" className={"accordionTableTitle"}>
                                  Work / Business Address
                                </td>
                              </tr>
                              <tr>
                                <td>Street/Subdivision</td>
                                <td>
                                  {props.state.businessAdd.street !==
                                    undefined &&
                                    props.state.businessAdd.street !== ""
                                    ? props.state.businessAdd.street
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td> Barangay</td>
                                <td>
                                  {props.state.businessAdd.barangay !==
                                    undefined &&
                                    props.state.businessAdd.barangay !== ""
                                    ? props.state.businessAdd.barangay
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>City/Municipality</td>
                                <td>
                                  {props.state.businessAdd.city !== undefined &&
                                    props.state.businessAdd.city !== null
                                    ? props.state.businessAdd.city
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Province</td>
                                <td>
                                  {props.state.businessAdd.province !==
                                    undefined &&
                                    props.state.businessAdd.province !== null
                                    ? props.state.businessAdd.province
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Country</td>
                                <td>
                                  {props.state.businessAdd.country !==
                                    undefined &&
                                    props.state.businessAdd.country !== ""
                                    ? props.state.businessAdd.country
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Zip Code</td>
                                <td>
                                  {props.state.businessAdd.zip !== undefined &&
                                    props.state.businessAdd.zip !== ""
                                    ? props.state.businessAdd.zip
                                    : "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={{ width: "100%" }}
                  expanded={props.state.panels.panelProDetails}
                  onChange={props.onTogglePanel("panelProDetails")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panelProDetails-content"
                    id="panelProDetails"
                  >
                    <label className={"accordionTitle"}>
                      Professional Details
                    </label>
                  </AccordionSummary>
                  <AccordionDetails>
                    <table className={"accordionTable"}>
                      <tbody>
                        <tr>
                          <td>Primary Occupation</td>
                          <td>
                            {props.state.work.primaryOccupation !== undefined &&
                              props.state.work.primaryOccupation !== ""
                              ? Array.isArray(
                                props.state.work.primaryOccupation
                              )
                                ? props.state.work.primaryOccupation.join(", ")
                                : props.state.work.primaryOccupation
                              : "N/A"}
                          </td>
                        </tr>
                        {(stats.tier === undefined ||
                          tier === 3 ||
                          isLegacy) && (
                            <>
                              <tr>
                                <td>Name of employer</td>
                                <td>
                                  {props.state.work.nameOfEmployer !==
                                    undefined &&
                                    props.state.work.nameOfEmployer !== ""
                                    ? props.state.work.nameOfEmployer
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Nature of business</td>
                                <td>
                                  {props.state.work.businessNature !==
                                    undefined &&
                                    props.state.work.businessNature !== ""
                                    ? props.state.work.businessNature
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Office Tel. No.</td>
                                <td>
                                  {props.state.work.businessNum !== undefined &&
                                    props.state.work.businessNum !== ""
                                    ? props.state.work.businessNum
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Annual Income</td>
                                <td>{incomeSelection}</td>
                              </tr>{" "}
                            </>
                          )}
                        <tr>
                          <td>Source of funds</td>
                          <td>
                            {props.state.work.sourceOfFunds !== undefined &&
                              props.state.work.sourceOfFunds !== ""
                              ? props.state.work.sourceOfFunds
                              : "N/A"}
                          </td>
                        </tr>
                        {/* {(stats.tier === undefined ||
                          tier === 3 ||
                          isLegacy) && (
                          <>
                            <tr>
                              <td colSpan="2" className={"accordionTableTitle"}>
                                Financial advisory information
                              </td>
                            </tr>
                            <tr>
                              <td>Financial advisor code</td>
                              <td>
                                {props.state.investmentManager
                                  .investmentManagerId !== undefined &&
                                props.state.investmentManager
                                  .investmentManagerId !== ""
                                  ? props.state.investmentManager
                                      .investmentManagerId
                                  : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td>Financial advisor name</td>
                              <td>
                                {props.state.investmentManager
                                  .investmentManagerName !== undefined &&
                                props.state.investmentManager
                                  .investmentManagerName !== ""
                                  ? props.state.investmentManager
                                      .investmentManagerName
                                  : "N/A"}
                              </td>
                            </tr>
                          </>
                        )} */}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </Accordion>
                {(stats.tier === undefined || tier === 3 || isLegacy) && (
                  <>
                    <Accordion
                      style={{ width: "100%" }}
                      expanded={props.state.panels.panelPEP}
                      onChange={props.onTogglePanel("panelPEP")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panelPEP-content"
                        id="panelPEP"
                      >
                        <label className={"accordionTitle"}>
                          PEP Declaration
                        </label>
                      </AccordionSummary>
                      <AccordionDetails>
                        <table className={"accordionTable"}>
                          <tbody>
                            <tr>
                              <td>
                                Have you worked in a government agency or
                                insitution?
                              </td>
                              <td>
                                {props.state.PEP.governmentWork ? "Yes" : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you have any relative who is/was an elected
                                government offical or who is/was an appointed
                                government offical?
                              </td>
                              <td>
                                {props.state.PEP.governmentRelative
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Please enumerate the banks you have an account
                                in:
                              </td>
                              <td>
                                {props.state.PEP.banks
                                  ? props.state.PEP.banks
                                  : "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      style={{ width: "100%" }}
                      expanded={props.state.panels.panelFATCA}
                      onChange={props.onTogglePanel("panelFATCA")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panelFATCA-content"
                        id="panelFATCA"
                      >
                        <label className={"accordionTitle"}>FATCA</label>
                      </AccordionSummary>
                      <AccordionDetails>
                        <table className={"accordionTable"}>
                          <tbody>
                            <tr>
                              <td>Are you a US citizen?</td>
                              <td>
                                {props.state.FATCA.usCitizen ? "Yes" : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>US resident?</td>
                              <td>
                                {props.state.FATCA.usResident ? "Yes" : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>US resident alien?</td>
                              <td>
                                {props.state.FATCA.usAlien ? "Yes" : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you have a current US telephone number?
                              </td>
                              <td>
                                {props.state.FATCA.usPhone ? "Yes" : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>Where you born in US?</td>
                              <td>{props.state.FATCA.usBorn ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                              <td>
                                Do you have a current US residence address?
                              </td>
                              <td>
                                {props.state.FATCA.usAddressResidence
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you have a current US mailing address?
                                (including US P.O)
                              </td>
                              <td>
                                {props.state.FATCA.usAddressMailing
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Have you issued a standing instruction to
                                transferfunds to an account
                              </td>
                              <td>
                                {props.state.FATCA.usAccount ? "Yes" : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Have you issued a power of Attorney or granted
                                signatory authority to a person with US address?
                              </td>
                              <td>{props.state.FATCA.usPOA ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                              <td>
                                Do you have “in care of” address or “hold mail”
                                address that is the sole address for an account
                                (whether such address in the US or outside the
                                US) ?
                              </td>
                              <td>
                                {props.state.FATCA.usCareOfAddress
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
                <Accordion
                  style={{ width: "100%" }}
                  expanded={props.state.panels.panelRisk}
                  onChange={props.onTogglePanel("panelRisk")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panelRisk-content"
                    id="panelRisk"
                  >
                    <label className={"accordionTitle"}>Risk Profiler</label>
                  </AccordionSummary>
                  <AccordionDetails>
                    {props.state.clientDetails !== undefined ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className={"riskContainer"}>
                          {Risk(props.state.clientDetails.riskProfile)}
                        </div>
                        <table className={"accordionTable"}>
                          <tbody>
                            {props.state.userRiskData.map((item, index) => (
                              <tr>
                                <td>{item.question}</td>
                                <td style={{ textAlign: "center" }}>
                                  {item.answer}
                                </td>
                                <td
                                  style={{
                                    color: `${item.color}`,
                                    textAlign: "right",
                                  }}
                                >
                                  {item.type}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </AccordionDetails>
                </Accordion>
                {(stats.tier === undefined || tier === 3 || isLegacy) && (
                  <Accordion
                    style={{ width: "100%" }}
                    expanded={props.state.panels.panelSettlement}
                    onChange={props.onTogglePanel("panelSettlement")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panelSettlement-content"
                      id="panelSettlement"
                    >
                      <label className={"accordionTitle"}>
                        Settlement Details
                      </label>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table className={"accordionTable"}>
                        <tbody>
                          <tr>
                            <td>Account Name</td>
                            <td>
                              {props.state.accountDetails
                                ? props.state.accountDetails.accountName &&
                                  props.state.accountDetails.accountName !== ""
                                  ? props.state.accountDetails.accountName
                                  : "N/A"
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Name of Bank</td>
                            <td>
                              {props.state.accountDetails
                                ? props.state.accountDetails.bankName &&
                                  props.state.accountDetails.bankName !== ""
                                  ? props.state.accountDetails.bankName
                                  : "N/A"
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Bank account number</td>
                            <td>
                              {props.state.accountDetails
                                ? props.state.accountDetails
                                  .bankAccountNumber !== undefined &&
                                  props.state.accountDetails
                                    .bankAccountNumber !== ""
                                  ? props.state.accountDetails.bankAccountNumber
                                  : "N/A"
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Current or Savings Account </td>
                            <td>
                              {props.state.accountDetails
                                ? props.state.accountDetails.savingsAccount
                                  ? "Savings Account"
                                  : "Current Account"
                                : "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
              {(validated.toString().toLowerCase() !== "verified" ||
                props.state.tierUpgradeStatus === "pending") && (
                  <>
                    <Divider />
                    <br />
                    <div>
                      <Paper elevation={3}>
                        <b>Comments: </b>
                        <br />
                        <span style={{ "white-space": "pre-line" }}>
                          {stringToWhiteSpaceConverter(comments)}
                        </span>
                      </Paper>
                    </div>
                    <br />
                    <Paper variant="outlined">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <span>Include Additional Documents Here</span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <DropzoneAreaBase
                                filesLimit={1}
                                onAdd={props.handleDropzone()}
                              />
                            </Grid>

                            {props.state.additionalDocuments.map(
                              (data, index) => {
                                return (
                                  <Chip
                                    id={`chip ${index}`}
                                    ariaLabel={`chip ${index}`}
                                    label={data.file.name}
                                    onDelete={props.handleDropzoneDelete(index)}
                                  />
                                );
                              }
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                    <br />
                    <Button
                      className={"btnDecline"}
                      data-type="decline"
                      onClick={props.handleDialogType()}
                    >
                      Decline
                    </Button>
                    <Tooltip
                      title={
                        !hasInvestmentManager
                          ? "Must assign a financial advisor to approve this."
                          : ""
                      }
                    >
                      <span className="btnSpanApprove">
                        <Button
                          disabled={!hasInvestmentManager ? true : false}
                          className={"btnApprove"}
                          data-type="approve"
                          onClick={props.handleDialogType()}
                        >
                          Approve
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                )}
            </>
          ) : (
            <>
              <div className={"content2Title"}>
                <div className={"content2IconBackground"}>
                  <AttachFileRounded className={"content2IconSize"} />
                </div>
                <div>
                  <label className={"content2ItemTitle"}>Attachments</label>
                  <br />
                </div>
              </div>

              <ClientAttachments props={props} tier={tier} />
            </>
          )
        ) : (
          <ClientTransactions props={props} />
        )}
      </div>

      <Dialog
        open={toggleDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={!isLoading ? props.onhandleDialogClose() : ""}
      >
        <div
          className={
            dialogType === "approve" || dialogType === "assign"
              ? "dialogIconBackground"
              : "dialogIconBackgroundDecline"
          }
        >
          <WarningRounded className={"dialogIcon"} />
        </div>
        <DialogContent>
          {dialogType === "approve" ? (
            <>
              <label className={"dialogTitle"}>Approve Client</label>
              <br />
              <label
                className={"dialogClientName"}
              >{`${name.firstName} ${name.lastName} ?`}</label>
              <br />
              <br />
              <label className={"dialogDescription"}>
                Do you want to approve the client application?
              </label>
            </>
          ) : dialogType === "assign" ? (
            <>
              <label className={"dialogTitle"}>
                Assign Financial Advisor to
              </label>
              <br />
              <label
                className={"dialogClientName"}
              >{`${name.firstName} ${name.lastName}`}</label>
              <br />
              <br />
              <FormControl
                variant="outlined"
                size="small"
                className={"dialogDropdown"}
              >
                <Select
                  className={"dialogDropdownSelect"}
                  onChange={props.onhandleManagerDropwdown()}
                  value={investmentManager.investmentManagerId}
                >
                  {managerList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : (
            <>
              <label className={"dialogTitle"}>Decline Client</label>
              <br />
              <label
                className={"dialogClientName"}
              >{`${name.firstName} ${name.lastName} ?`}</label>
              <br />
              <br />
              <span className={"dialogDescription"}>
                Please enter your comments about this
              </span>
              <br />
              <span className={"dialogDescription"}>
                client's application before declining.
              </span>
              <br />
              <br />
              <TextField
                name="commentToAdd"
                inputProps={{ className: "companyEditTextBox" }}
                variant="outlined"
                value={commentToAdd}
                onChange={props.onChange()}
                placeholder="Admin Comments"
                autoComplete="off"
                multiline
                fullWidth="true"
                rows={8}
              ></TextField>
              <br />
              <br />
              <label className={"dialogDescription"}>
                Do you want to decline the client's application?
              </label>
            </>
          )}
        </DialogContent>
        <br />
        {!isLoading ? (
          <>
            <Button
              onClick={() => props.onhandleApproveConfirm(false)}
              className={"dialogApproveButton"}
            >
              {dialogType === "approve"
                ? "Yes, Approve"
                : dialogType === "assign"
                  ? "Confirm"
                  : "Yes, Decline"}
            </Button>
            <Button
              onClick={props.onhandleDialogClose()}
              className={"dialogCancelButton"}
            >
              Cancel
            </Button>
            {dialogType === "approve" && isLegacy && (
              <Tooltip
                title="Sets the user to tier 3 automatically"
                aria-label="tooltip"
              >
                <Button
                  className={"dialogLegacyButton"}
                  style={{ color: "#256141 !important" }}
                  onClick={() => props.onhandleApproveConfirm(true)}
                >
                  Approve Legacy User
                </Button>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            <CircularProgress className={"dialogLoading"} />
            <br />
            <br />
          </>
        )}
      </Dialog>

      <Backdrop
        style={{ zIndex: "9999" }}
        open={toggleShowImage}
        onClick={props.onCloseImage()}
      >
        {imageType !== -100 && (
          <img src={imgSrc} alt="" className={"imagePreview"} />
        )}
      </Backdrop>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={toggleAlert}
        onClose={props.onhandleClose()}
        key={1}
      >
        <Alert onClose={props.onhandleClose()} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ClientProfileForm;
